import React from 'react';
import styles from './layout.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Layout = ({ center, fullscreen, small, children }) => {
  return (
    <section
      className={classNames(
        styles.layout,
        {
          [styles.center]: center === true,
        },
        {
          [styles.small]: small === true,
        },
        {
          [styles.fullscreen]: fullscreen === true,
        }
      )}
    >
      {children}
    </section>
  );
};

Layout.propTypes = {
  center: PropTypes.bool,
  small: PropTypes.bool,
  fullscreen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  center: false,
  small: false,
  fullscreen: false,
  children: null,
};

export default Layout;
