import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import Main from '../../components/main/main';
import { useHistory, useLocation } from 'react-router-dom';
import { database } from '../../utils/firebase';
import { SettingsContext } from '../../contexts/settings-provider';

import { ref, get, off, set, onValue } from 'firebase/database';

import Join from './join/join';
import End from './end/end';
import Results from './results/results';
import Introduction from './introduction/introduction';
import Question from './question/question';
import Loading from '../../components/loading/loading';
import DisplayMap from '../../components/displayMap/displayMap';
import scoreAnswer from '../../utils/scoreAnswer';
import playSound from '../../utils/playSound';

import idleMusicSrc from '../../assets/sound/music.mp3';
import playMusicSrc from '../../assets/sound/round.mp3';

import styles from './display.module.scss';

const Play = () => {
  const history = useHistory();
  const location = useLocation();
  const timer = useRef(null);
  const musicRef = useRef(null);
  const effectRef = useRef(null);

  const [settings] = useContext(SettingsContext);

  const [muted, setMuted] = useState(true);
  const [music, setMusic] = useState('idle');

  const [roomCode] = useState(location.state.room);
  const [validRoom, setValidRoom] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayStage, setDisplayStage] = useState('lobby');
  const [mapStage, setMapStage] = useState('quesiton');
  const [hardcore, setHardcore] = useState(false);
  const [mapTheme, setMapTheme] = useState(0);

  const [metaData, setMetaData] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionTime, setQuestionTime] = useState(0);

  const [joinedPlayers, setJoinedPlayers] = useState([]);
  const [answeredPlayers, setAnsweredPlayers] = useState([]);
  const [scoredPlayers, setScoredPlayers] = useState([]);

  const [mapPos, setMapPos] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState({ start: 0, end: 21 });

  const attemptPlaySound = useCallback(
    (sound) => {
      if (muted === false) {
        playSound(sound);
      }
    },
    [muted]
  );

  useEffect(() => {
    if (muted === false && musicRef.current) {
      switch (music) {
        case 'idle':
          musicRef.current.play();
          musicRef.current.volume = 0.4;
          effectRef.current.pause();
          break;
        case 'play':
          musicRef.current.volume = 0.2;
          effectRef.current.volume = 0.5;
          effectRef.current.play();
          break;
        default:
          console.log(`Unknown music ${music}`);
          break;
      }
    } else if (muted === true && musicRef.current) {
      musicRef.current.pause();
    }
  }, [muted, music]);

  useEffect(() => {
    if (displayStage === 'question') {
      setMusic('play');
    } else {
      setMusic('idle');
    }
  }, [displayStage]);

  // Navigation
  const leaveGame = useCallback(() => history.push('/saved'), [history]);
  const backToJoin = () => {
    setDisplayStage('lobby');
  };
  const readyGame = () => {
    setDisplayStage('intro');
  };
  const startGame = () => {
    setQuestionTime(Date.now());
    setQuestionIndex(0);
    setDisplayStage('question');
    setMapStage('question');
  };
  const nextQuestion = () => {
    updateHighscore();
    setQuestionTime(Date.now());
    setAnsweredPlayers([]);
    if (questionIndex === questions.length - 1) {
      endGame();
    } else {
      setDisplayStage('question');
      setMapStage('question');
      setQuestionIndex(questionIndex + 1);
    }
  };
  const showResults = useCallback(() => {
    setMapStage('results');
    setDisplayStage('results');
    attemptPlaySound('roundEnd');
  }, [attemptPlaySound]);

  const endGame = () => {
    setDisplayStage('end');
    attemptPlaySound('end');
  };
  const closeGame = () => {
    leaveGame();
  };

  // Verify room
  useEffect(() => {
    if (roomCode) {
      const gameRef = ref(database, `games/${roomCode}`);
      get(gameRef).then((snapshot) => {
        if (!snapshot.exists()) {
          console.log('no data');
          leaveGame();
        } else {
          setValidRoom(true);
          const answersRef = ref(database, `games/${roomCode}/answers`);
          const highscoreRef = ref(database, `games/${roomCode}/highscore`);
          set(answersRef, null);
          set(highscoreRef, null);
        }
      });
    } else {
      console.log('No room');
      leaveGame();
    }
  }, [leaveGame, roomCode]);

  // Get generic game data
  useEffect(() => {
    const settingsRef = ref(database, `games/${roomCode}/settings`);
    const metaRef = ref(database, `games/${roomCode}/info`);
    const questionsRef = ref(database, `games/${roomCode}/questions`);
    const joinedPlayersRef = ref(database, `games/${roomCode}/players`);
    if (validRoom) {
      onValue(settingsRef, (snapshot) => {
        const newSettingsData = snapshot.val();
        setHardcore(newSettingsData.hard === true ? true : false);
        setMapTheme(newSettingsData.theme);
      });
      onValue(metaRef, (snapshot) => {
        const newMetaData = snapshot.val();
        setMetaData({
          image: newMetaData.image,
          title: newMetaData.name,
          description: newMetaData.description,
        });
      });
      onValue(questionsRef, (snapshot) => {
        const newQuestions = snapshot.val();
        setQuestions([...newQuestions]);
      });
      onValue(joinedPlayersRef, (snapshot) => {
        const newJoinedPlayers = [];
        const joinedPlayersData = snapshot.val();
        attemptPlaySound('join');

        for (const key in joinedPlayersData) {
          newJoinedPlayers.push({ name: joinedPlayersData[key], uid: key });
        }
        setJoinedPlayers(newJoinedPlayers);
      });
    }
    return () => {
      off(metaRef);
      off(questionsRef);
      off(joinedPlayersRef);
    };
  }, [validRoom, roomCode, attemptPlaySound]);

  // Get question specificData
  useEffect(() => {
    const answersRef = ref(
      database,
      `games/${roomCode}/answers/${questionIndex}`
    );
    if (validRoom) {
      onValue(answersRef, (snapshot) => {
        const answersData = snapshot.val();
        attemptPlaySound('join');

        const newAnswers = [];
        for (const key in answersData) {
          const newAnswer = answersData[key];

          newAnswers.push({
            uid: key,
            name: newAnswer.name,
            time: newAnswer.timestamp - questionTime,
            answer: { lat: newAnswer.answer.lat, lng: newAnswer.answer.lng },
          });
        }
        setAnsweredPlayers(newAnswers);
      });
    }
    return () => {
      off(answersRef);
    };
  }, [
    validRoom,
    displayStage,
    roomCode,
    questionTime,
    questionIndex,
    attemptPlaySound,
  ]);

  // Score players
  useEffect(() => {
    if (validRoom) {
      const highscoreRef = ref(database, `games/${roomCode}/highscore`);
      get(highscoreRef).then((snapshot) => {
        const highscoreList = snapshot.val();
        const newScoredPlayers = [];
        const scoredUids = [];
        answeredPlayers.forEach((answer) => {
          const points = scoreAnswer(
            answer.answer,
            questions[questionIndex].location,
            answer.time,
            settings.time.question
          );
          const score = highscoreList?.[answer.uid];
          scoredUids.push(answer.uid);
          newScoredPlayers.push({
            time: Date.now(),
            uid: answer.uid,
            name: answer.name,
            answer: answer.answer,
            score: score ? score : 0,
            points: points,
          });
        });
        joinedPlayers.forEach((player) => {
          if (scoredUids.indexOf(player.uid) === -1) {
            const score = highscoreList?.[player.uid];
            newScoredPlayers.push({
              uid: player.uid,
              name: player.name,
              score: score ? score : 0,
              points: 0,
            });
          }
        });
        setScoredPlayers(newScoredPlayers);
      });
    }
  }, [
    questionTime,
    validRoom,
    answeredPlayers,
    joinedPlayers,
    questions,
    roomCode,
    questionIndex,
    settings.time.question,
  ]);

  const updateHighscore = useCallback(() => {
    const highscoreRef = ref(database, `games/${roomCode}/highscore`);
    const newHighscore = {};
    scoredPlayers.forEach((player) => {
      newHighscore[player.uid] = player.score + player.points;
    });
    set(highscoreRef, newHighscore);
  }, [scoredPlayers, roomCode]);

  // Update room status
  useEffect(() => {
    if (validRoom) {
      const statusRef = ref(database, `games/${roomCode}/status`);
      set(statusRef, displayStage);
    }
  }, [validRoom, displayStage, roomCode]);

  useEffect(() => {
    if (displayStage === 'question') {
      timer.current = setTimeout(() => {
        showResults();
      }, settings.time.question);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [displayStage, settings.time.question, showResults]);

  useEffect(() => {
    if (
      displayStage === 'question' &&
      answeredPlayers.length === joinedPlayers.length
    ) {
      clearTimeout(timer.current);
      showResults();
    }
  }, [displayStage, answeredPlayers, joinedPlayers, showResults]);

  // Update room question
  useEffect(() => {
    if (validRoom) {
      const statusRef = ref(database, `games/${roomCode}/currentQuestion`);
      set(statusRef, questionIndex);
    }
  }, [validRoom, questionIndex, roomCode]);

  // Game is ready
  useEffect(() => {
    if (validRoom && metaData) {
      setLoading(false);
    }
  }, [validRoom, metaData]);

  // Manipulate map
  useEffect(() => {
    if (displayStage === 'question') {
      attemptPlaySound('roundStart');
      const question = questions[questionIndex];
      const newLocation = question.location;
      setMapZoom({ start: question.zoom.start, end: question.zoom.end });
      setMapPos(newLocation);
    }
  }, [
    settings,
    questionTime,
    displayStage,
    questions,
    questionIndex,
    attemptPlaySound,
  ]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Main>
      <audio ref={musicRef} src={idleMusicSrc} loop autoPlay={!muted} />
      <audio ref={effectRef} src={playMusicSrc} loop autoPlay={false} />
      {displayStage === 'lobby' && <div className={styles.mute}></div>}
      <DisplayMap
        hardcore={hardcore}
        theme={mapTheme}
        position={mapPos}
        zoom={mapZoom}
        mapStage={mapStage}
        scoredPlayers={scoredPlayers}
      />

      {displayStage === 'lobby' && (
        <Join
          room={roomCode}
          joinedPlayers={joinedPlayers}
          startGame={readyGame}
          leaveGame={leaveGame}
          muted={muted}
          toggleMuted={() => {
            setMuted(!muted);
          }}
        />
      )}
      {displayStage === 'intro' && (
        <Introduction
          metaData={metaData}
          totalQuestions={questions.length}
          startQuiz={startGame}
          backToJoin={backToJoin}
        />
      )}
      {displayStage === 'question' && (
        <Question
          image={questions[questionIndex]?.image}
          question={questions[questionIndex].question}
          questionIndex={questionIndex}
          questionsTotal={questions.length}
          questionTime={settings.time.question}
          joinedPlayers={joinedPlayers}
          answeredPlayers={answeredPlayers}
          showResults={showResults}
        />
      )}
      {displayStage === 'results' && (
        <Results scoredPlayers={scoredPlayers} nextQuestion={nextQuestion} />
      )}
      {displayStage === 'end' && (
        <End scoredPlayers={scoredPlayers} closeGame={closeGame} />
      )}
    </Main>
  );
};

export default Play;
