import React, { useState } from 'react';
import styles from './savedTile.module.scss';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';
import { MdMoreHoriz, MdClose } from 'react-icons/md';
import classNames from 'classnames';
import placeholder from '../../assets/placeholder.png';

const SavedTile = ({
  image,
  name,
  lastPlayed,
  editQuiz,
  launchQuiz,
  duplicateQuiz,
  deleteQuiz,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div
      className={styles.tile}
      onPointerLeave={() => {
        setMenuOpen(false);
      }}
    >
      <div className={styles.menu}>
        <button className={styles.toggle} onClick={toggleMenu}>
          {menuOpen ? <MdClose className={styles.close} /> : <MdMoreHoriz />}
        </button>
        <div
          className={classNames(styles.actions, {
            [styles.open]: menuOpen === true,
          })}
        >
          <button onClick={editQuiz} className={styles.link}>
            Edit
          </button>
          <button onClick={launchQuiz} className={styles.link}>
            Launch
          </button>
          <button onClick={duplicateQuiz} className={styles.link}>
            Duplicate
          </button>
          <button onClick={deleteQuiz} className={styles.link}>
            Delete
          </button>
        </div>
      </div>
      <div className={styles.image} onClick={editQuiz}>
        {image ? (
          <img src={image} alt={name} />
        ) : (
          <img src={placeholder} alt={name} />
        )}
      </div>
      <div className={styles.meta} onClick={editQuiz}>
        <div className={styles.name}>{name}</div>
        <div className={styles.date}>
          Last edited: {formatDate(lastPlayed / 1000)}
        </div>
      </div>
    </div>
  );
};

SavedTile.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  lastPlayed: PropTypes.number,
  editQuiz: PropTypes.func,
  launchQuiz: PropTypes.func,
  duplicateQuiz: PropTypes.func,
  deleteQuiz: PropTypes.func,
};

SavedTile.defaultProps = {
  image: null,
  name: null,
  lastPlayed: null,
  editQuiz: null,
  launchQuiz: null,
  duplicateQuiz: null,
  deleteQuiz: null,
};

export default SavedTile;
