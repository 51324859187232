import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './join.module.scss';
import Main from '../../../components/main/main';
import Layout from '../../../components/layout/layout';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';
import Heading from '../../../components/heading/heading';
import Error from '../../../components/error/error';
import Loading from '../../../components/loading/loading';

const Join = ({
  room,
  validRoom,
  setRoom,
  name,
  validName,
  setName,
  error,
}) => {
  const [newRoom, setNewRoom] = useState(room);
  const [newName, setNewName] = useState(name);
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    if (newRoom && newName && newRoom.length > 0 && newName.length > 0) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [newRoom, newName]);

  if (validRoom && validName) {
    return <Loading />;
  }

  return (
    <Main>
      <Layout fullscreen>
        <div className={styles.join}>
          {error && <Error>{error}</Error>}
          {validRoom === validName && (
            <Heading className={styles.title}>Join game</Heading>
          )}
          {validRoom && (
            <Heading className={styles.title}>
              Room: <span className={styles.room}>{room}</span>
            </Heading>
          )}
          {validName && (
            <Heading className={styles.title}>
              Join game, <span className={styles.name}>{name}</span>
            </Heading>
          )}
          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              if (validForm === true) {
                setRoom(newRoom);
                setName(newName);
              }
              return false;
            }}
          >
            <div className={styles.inputs}>
              {!validRoom && (
                <Input
                  placeholder={'Room code'}
                  onChange={setNewRoom}
                  name={newRoom}
                  uppercase
                  stretch
                  alternative
                  centered
                />
              )}
              {!validName && (
                <Input
                  placeholder={'Display name'}
                  onChange={setNewName}
                  name={newName}
                  stretch
                  alternative
                  centered
                />
              )}
            </div>
            <Button disabled={!validForm}>Join game</Button>
          </form>
        </div>
      </Layout>
    </Main>
  );
};

Join.propTypes = {
  room: PropTypes.string,
  validRoom: PropTypes.bool,
  setRoom: PropTypes.func.isRequired,
  name: PropTypes.string,
  validName: PropTypes.bool,
  setName: PropTypes.func.isRequired,
  error: PropTypes.string,
};

Join.defaultProps = {
  room: '',
  validRoom: false,
  setRoom: null,
  name: '',
  validName: false,
  setName: null,
  error: '',
};

export default Join;
