import React from 'react';

import styles from './loading.module.scss';
import PropTypes from 'prop-types';
import loadingAnim from '../../assets/loading.mp4';

const Loading = ({ noVideo, message }) => {
  return (
    <div className={styles.loading}>
      {!noVideo && (
        <video
          playsInline
          muted
          autoPlay
          loop
          src={loadingAnim}
          className={styles.animation}
        />
      )}
      <span className={styles.message}>{message ? message : 'Loading...'}</span>
    </div>
  );
};

Loading.propTypes = {
  noVideo: PropTypes.bool,
  message: PropTypes.string,
};

Loading.defaultProps = {
  noVideo: false,
  message: null,
};

export default Loading;
