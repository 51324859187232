import React from 'react';
import styles from './publicTile.module.scss';
import PropTypes from 'prop-types';
import Heading from '../heading/heading';
import Button from '../button/button';

const PublicTile = ({ image, name, description, startQuiz }) => {
  return (
    <div className={styles.tile}>
      <div className={styles.image}>
        <img src={image} alt={name} />
      </div>
      <div className={styles.content}>
        <Heading className={styles.name}>{name}</Heading>
        <p className={styles.desc}>{description}</p>
        <div className={styles.buttons}>
          <Button onClick={startQuiz}>Start quiz</Button>
        </div>
      </div>
    </div>
  );
};

PublicTile.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  startQuiz: PropTypes.func,
};

PublicTile.defaultProps = {
  image: null,
  name: null,
  description: null,
  startQuiz: null,
};

export default PublicTile;
