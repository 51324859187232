import React, { useRef, useState, useEffect } from 'react';
import styles from './answerMap.module.scss';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import PropTypes from 'prop-types';
import getMapTheme from '../../utils/getMapTheme';

mapboxgl.accessToken =
  'pk.eyJ1Ijoibm90ZXRoZW5vdGUiLCJhIjoiY2t3ZGZjd3RmMXV2cTJvcW1wcm5mczg3ZCJ9.b4fGC2AoXtvBLqgSwX4oew';

const AnswerMap = ({ setPosition, hardcore, theme }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(2);

  let mapTheme = getMapTheme(hardcore, theme);

  useEffect(() => {
    if (map.current) return;
    /*
    const getRandomPos = () => {
      return Math.min(Math.max(Math.random() * 180 - 90, -50), 50);
    };
    const randomPos = {
      lat: getRandomPos(),
      lng: getRandomPos(),
    };
    */
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapTheme,
      center: [11.06322453640722, 50.886101296387835],
      zoom: zoom,
    });
    setPosition({
      lat: 11.06322453640722,
      lng: 50.886101296387835,
    });
  });

  useEffect(() => {
    if (!map.current) return;
    const updatePosition = () => {
      setPosition({
        lng: parseFloat(map.current.getCenter().lng.toFixed(6)),
        lat: parseFloat(map.current.getCenter().lat.toFixed(6)),
      });
    };
    map.current.on('move', updatePosition);
    return () => {
      map.current.off('move', updatePosition);
    };
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.map} ref={mapContainer}></div>
      <div className={styles.marker} />
    </div>
  );
};

AnswerMap.propTypes = {
  hardcore: PropTypes.bool,
  theme: PropTypes.number,
  setPosition: PropTypes.func,
};

AnswerMap.defaultProps = {
  hardcore: false,
  theme: 0,
  setPosition: null,
};

export default AnswerMap;
