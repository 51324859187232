import React, { useState, useEffect, useCallback } from 'react';
import styles from './create.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { database, auth } from '../../utils/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { ref, set, get, push, serverTimestamp } from 'firebase/database';

import Main from '../../components/main/main';
import Header from '../../components/header/header';
import General from './general/general';
import Questions from './questions/questions';
import Loading from '../../components/loading/loading';

const Create = () => {
  const functions = getFunctions();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [createStep, setCreateStep] = useState('general');
  const [settings, setSettings] = useState({
    name: '',
    description: '',
    image: '',
    free: false,
    hard: false,
    imperial: false,
    theme: 0,
    owner: auth.currentUser.uid,
  });
  const [validSettings, setValidSettings] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [validQuestions, setValidQuestions] = useState(false);
  const [validQuiz, setValidQuiz] = useState(false);
  const [launching, setLaunching] = useState(false);

  const [dataKey, setDataKey] = useState(null);

  const validateSettings = useCallback((testSettings) => {
    if (testSettings.name !== '') {
      return true;
    } else {
      return false;
    }
  }, []);

  const validateQuestions = useCallback((testQuestions) => {
    if (testQuestions.length > 1) {
      return true;
    } else {
      return false;
    }
  }, []);

  const validateQuiz = useCallback((testSettings, testQuestions) => {
    if (testSettings && testQuestions) {
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.key) {
      const getDocData = async () => {
        const savedRef = ref(
          database,
          `users/${auth.currentUser.uid}/saved/${location.state.key}`
        );

        get(savedRef).then((snapshot) => {
          if (snapshot.exists()) {
            if (!location.state.duplicate) {
              setDataKey(location.state.key);
            }

            const saved = snapshot.val();
            const savedSettings = {
              name: saved.name,
              description: saved.description,
              image: saved.image,
              free: saved.free,
              hard: saved.hard,
              imperial: saved.imperial,
              theme: saved.theme,
              owner: auth.currentUser.uid,
            };
            setSettings(savedSettings);
            setQuestions(saved.questions);

            const validSavedSettings = validateSettings(savedSettings);
            const validSavedQuestions = saved.questions
              ? validateQuestions(saved.questions)
              : false;
            const validSavedQuiz = setValidQuiz(
              validateQuiz(validSavedSettings, validSavedQuestions)
            );
            setValidQuiz(validSavedQuiz);

            if (location.state.launch === true) {
              setLaunching(true);
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        });
      };
      getDocData();
    } else {
      setLoading(false);
    }
  }, [location, validateSettings, validateQuestions, validateQuiz]);

  useEffect(() => {
    const valid = validateSettings(settings);
    setValidSettings(valid);
  }, [settings, validateSettings]);

  useEffect(() => {
    if (questions) {
      const valid = validateQuestions(questions);
      setValidQuestions(valid);
    }
  }, [questions, validateQuestions]);

  useEffect(() => {
    const valid = validateQuiz(validSettings, validQuestions);
    setValidQuiz(valid);
  }, [validSettings, validQuestions, validateQuiz]);

  const goToQuestions = () => {
    setCreateStep('questions');
  };

  const goToSettings = () => {
    setCreateStep('general');
  };

  const exitQuiz = useCallback(() => history.push('/saved'), [history]);

  const saveQuiz = useCallback(async () => {
    let newDataKey = dataKey;

    if (settings.name !== '') {
      setLoading(true);
      const saveData = {
        ...settings,
        questions: questions ? questions : null,
      };

      if (!newDataKey) {
        newDataKey = push(
          ref(database, `users/${auth.currentUser.uid}/saved`)
        ).key;
      }

      const freeRef = ref(database, `public/${newDataKey}`);
      const savedRef = ref(
        database,
        `users/${auth.currentUser.uid}/saved/${newDataKey}`
      );
      if (settings.free && validQuiz === true) {
        set(freeRef, { ...saveData, edited: serverTimestamp() });
      } else {
        set(freeRef, null);
      }
      set(savedRef, { ...saveData, edited: serverTimestamp() }).then(() => {
        if (launching !== true) {
          exitQuiz();
          setLoading(false);
        }
      });
    }
  }, [settings, dataKey, questions, exitQuiz, launching, validQuiz]);

  const launchQuiz = useCallback(
    (code) => {
      history.push({
        pathname: '/host',
        state: { room: code },
      });
    },
    [history]
  );

  useEffect(() => {
    if (launching === true) {
      setLoading(true);
      saveQuiz();
      if (validQuiz && dataKey) {
        setLaunching(false);
        const createNewRoom = httpsCallable(functions, 'createNewRoom');
        createNewRoom({ game: dataKey, public: false }).then((result) => {
          const data = result.data;
          launchQuiz(data);
        });
      } else {
        console.log('Could not launch');
        setLoading(false);
        setLaunching(false);
      }
    }
  }, [launching, validQuiz, dataKey, launchQuiz, functions, saveQuiz]);

  if (loading) {
    return <Loading message={launching === true ? 'Creating game' : null} />;
  }
  return (
    <Main>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {createStep === 'general' && (
            <General
              settings={settings}
              setSettings={setSettings}
              exitQuiz={exitQuiz}
              saveQuiz={saveQuiz}
              goToQuestions={goToQuestions}
              validSettings={validSettings}
              validQuiz={validQuiz}
              launchQuiz={() => {
                setLaunching(true);
              }}
            />
          )}
          {createStep === 'questions' && (
            <Questions
              questions={questions}
              setQuestions={setQuestions}
              goToSettings={goToSettings}
              saveQuiz={saveQuiz}
              validQuiz={validQuiz}
              launchQuiz={() => {
                setLaunching(true);
              }}
            />
          )}
        </div>
      </div>
    </Main>
  );
};

export default Create;
