import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { SettingsContext } from '../../../contexts/settings-provider/';
import Confetti from 'react-confetti';

import styles from './end.module.scss';
import Main from '../../../components/main/main';
import Button from '../../../components/button/button';
import Heading from '../../../components/heading/heading';
import Layout from '../../../components/layout/layout';

import placeNumberToString from '../../../utils/placeNumberToString';

const End = ({ scoredPlayers, closeGame }) => {
  const [settings] = useContext(SettingsContext);

  function sortByScore(a, b) {
    if (a.score + a.points < b.score + b.points) {
      return 1;
    }
    if (a.score + a.points > b.score + b.points) {
      return -1;
    }
    return 0;
  }

  const singleCountLength = Math.min(scoredPlayers.length, 5);

  const playerList = scoredPlayers.sort(sortByScore).map((player, i) => {
    return (
      <div key={player.uid} className={styles.player}>
        <div className={styles.place}>{placeNumberToString(i + 1)}</div>
        <div className={styles.name}>{player.name}</div>
        <div className={styles.score}>
          <CountUp
            start={0}
            end={player.score + player.points}
            duration={settings.time.points / 1000}
            delay={
              i < singleCountLength
                ? ((singleCountLength - i) * settings.time.points) / 1000
                : 0
            }
            decimals={0}
            suffix=" pts"
          />
        </div>
      </div>
    );
  });

  return (
    <Main background>
      <div className={styles.confetti}>
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      </div>
      <Layout fullscreen>
        <div className={styles.content}>
          <Heading>And the winner is...</Heading>
          <div className={styles.list}>
            <div className={styles.legend}>
              <div className={styles.label}>Place</div>
              <div className={styles.label}>Name</div>
              <div className={styles.label}>Score</div>
            </div>
            {playerList}
          </div>
          <div className={styles.actions}>
            <Button onClick={closeGame}>Exit quiz</Button>
          </div>
        </div>
      </Layout>
    </Main>
  );
};

End.propTypes = {
  scoredPlayers: PropTypes.array,
  closeGame: PropTypes.func.isRequired,
};

End.defaultProps = {
  scoredPlayers: [],
  closeGame: null,
};

export default End;
