import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './questions.module.scss';
import { MdChevronLeft, MdExpandLess, MdExpandMore } from 'react-icons/md';

import Heading from '../../../components/heading/heading';
import Button from '../../../components/button/button';
import SmallButton from '../../../components/smallButton/smallButton';
import Loading from '../../../components/loading/loading';
import classNames from 'classnames';
import Input from '../../../components/input/input';
import ImageInput from '../../../components/imageInput/imageInput';
import SearchMap from '../../../components/searchMap/searchMap';
import Slider from '../../../components/slider/slider';

const Questions = ({
  questions,
  setQuestions,
  goToSettings,
  saveQuiz,
  launchQuiz,
  validQuiz,
}) => {
  const [loading, setLoading] = useState(true);
  const [questionList, setQuestionList] = useState(questions);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const questionCount = useRef(1);
  const maxQuestions = 50;

  const addNewQuestion = useCallback(() => {
    if (questionList.length < maxQuestions) {
      const newQuestions = [...questionList];
      const newQuestion = {
        key: `question-${newQuestions.length}-${Math.round(
          Math.random() * 10000
        )}`,
        text: `New question ${questionCount.current}`,
        image: '',
        location: {
          lat: 59.914244712818146,
          lng: 10.743394434110769,
        },
        startZoom: 2,
        endZoom: 15,
      };
      questionCount.current++;
      newQuestions.push(newQuestion);
      setQuestionList(newQuestions);
      setCurrentQuestion(newQuestions.length - 1);
    }
  }, [questionList, setQuestionList]);

  useEffect(() => {
    if (questionList.length === 0) {
      addNewQuestion();
    }
    setLoading(false);
  }, [questionList, addNewQuestion]);

  const deleteQuestion = useCallback(() => {
    const newQuestions = [...questionList];
    if (newQuestions.length > 1) {
      const newCurrentQuestion = Math.max(0, currentQuestion - 1);
      setCurrentQuestion(newCurrentQuestion);
      newQuestions.splice(currentQuestion, 1);
      setQuestionList(newQuestions);
    }
  }, [questionList, setQuestionList, currentQuestion]);

  const updateCurrentQuestion = useCallback(
    (key, value) => {
      if (value !== undefined) {
        const newQuestions = [...questionList];
        newQuestions[currentQuestion][key] = value;
        setQuestionList(newQuestions);
      }
    },
    [questionList, setQuestionList, currentQuestion]
  );

  const moveQuestionUp = (index) => {
    if (index !== 0) {
      const newQuestions = [...questionList];
      const inTransit = newQuestions[index];
      newQuestions[index] = newQuestions[index - 1];
      newQuestions[index - 1] = inTransit;
      setQuestionList(newQuestions);
    }
  };

  const moveQuestionDown = (index) => {
    if (index !== questionList.length - 1) {
      const newQuestions = [...questionList];
      const inTransit = newQuestions[index];
      newQuestions[index] = newQuestions[index + 1];
      newQuestions[index + 1] = inTransit;
      setQuestionList(newQuestions);
    }
  };

  useEffect(() => {
    const currentQuestionList = [...questionList];
    const newQuestionList = [];

    for (let i = 0; i < currentQuestionList.length; i++) {
      const newQuestion = currentQuestionList[i];
      if (newQuestion.text !== '') {
        newQuestionList.push(newQuestion);
      }
    }
    setQuestions(newQuestionList);
  }, [questionList, setQuestions]);

  const questionItems = questionList.map((question, i) => {
    return (
      <div
        key={question.key}
        className={classNames(styles.question, {
          [styles.active]: i === currentQuestion,
        })}
      >
        <div
          className={styles.info}
          onClick={() => {
            setCurrentQuestion(i);
          }}
        >
          <div className={styles.index}>Question {i + 1}</div>
          <div className={styles.text}>{question.text}</div>
        </div>
        <div className={styles.move}>
          <span
            className={styles.icon}
            onClick={() => {
              moveQuestionUp(i);
            }}
          >
            <MdExpandLess />
          </span>
          <span
            className={styles.icon}
            onClick={() => {
              moveQuestionDown(i);
            }}
          >
            <MdExpandMore />
          </span>
        </div>
      </div>
    );
  });

  if (loading) {
    return <Loading />;
  }
  return (
    <div className={styles.questions}>
      <div className={styles.header}>
        <SmallButton onClick={goToSettings} icon={MdChevronLeft} small>
          Back to game settings
        </SmallButton>
        <Button onClick={launchQuiz} disabled={!validQuiz}>
          Start quiz
        </Button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <div className={styles.actions}>
            <Heading className={styles.title}>Questions</Heading>
            {questionList.length < maxQuestions && (
              <Button secondary onClick={addNewQuestion}>
                New question
              </Button>
            )}
          </div>
          <div className={styles.list}>
            <div className={styles.items}>{questionItems}</div>
          </div>
          <div className={classNames(styles.actions, styles.center)}>
            <Button onClick={launchQuiz} disabled={!validQuiz}>
              Start quiz
            </Button>
          </div>
        </div>
        <div className={styles.data}>
          <div className={styles.header}>
            <Heading className={styles.heading}>
              Quesition {currentQuestion + 1}
            </Heading>
          </div>
          <div className={styles.form}>
            <div className={styles.section}>
              <div className={styles.label}>Question text</div>
              <div className={styles.input}>
                <Input
                  stretch
                  value={questionList?.[currentQuestion]?.['text']}
                  onChange={(e) => {
                    updateCurrentQuestion('text', e);
                  }}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.label}>Image</div>
              <div className={styles.input}>
                <ImageInput
                  stretch
                  value={questionList?.[currentQuestion]?.['image']}
                  onChange={(e) => {
                    updateCurrentQuestion('image', e);
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.section, styles.noBorder)}>
              <div className={styles.label}>Location</div>
              <div className={styles.input}>
                <SearchMap
                  position={questionList?.[currentQuestion]?.['location']}
                  startZoom={questionList?.[currentQuestion]?.['startZoom']}
                  endZoom={questionList?.[currentQuestion]?.['endZoom']}
                  setPosition={(e) => {
                    updateCurrentQuestion('location', {
                      lat: e.lat,
                      lng: e.lng,
                    });
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.section, styles.noBorder)}>
              <div className={styles.label}>Zoom start {'&'} stop</div>
              <div className={styles.input}>
                <Slider
                  step={0.1}
                  min={0.5}
                  max={21}
                  maxValue={questionList?.[currentQuestion]?.['endZoom']}
                  minValue={questionList?.[currentQuestion]?.['startZoom']}
                  onChange={(e) => {
                    updateCurrentQuestion('startZoom', e[0]);
                    updateCurrentQuestion('endZoom', e[1]);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div>
              {questionList.length > 1 && (
                <SmallButton onClick={deleteQuestion} small>
                  Delete question
                </SmallButton>
              )}
            </div>
            <Button secondary onClick={saveQuiz}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  goToSettings: PropTypes.func.isRequired,
  saveQuiz: PropTypes.func.isRequired,
  launchQuiz: PropTypes.func.isRequired,
  validQuiz: PropTypes.bool,
};

Questions.defaultProps = {
  questions: [],
  setQuestions: null,
  goToSettings: null,
  saveQuiz: null,
  launchQuiz: null,
  validQuiz: false,
};

export default Questions;
