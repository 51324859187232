import React from 'react';
import styles from './smallButton.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SmallButton = ({
  icon,
  small,
  active,
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={classNames(
        className,
        styles.button,
        {
          [styles.active]: active === true,
        },
        {
          [styles.small]: small === true,
        }
      )}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon()}</div>}
      {children}
    </button>
  );
};

SmallButton.propTypes = {
  icon: PropTypes.func,
  small: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SmallButton.defaultProps = {
  icon: null,
  small: false,
  active: false,
  className: null,
  children: null,
};

export default SmallButton;
