import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import { ref, onValue, set, off, query, orderByChild } from 'firebase/database';

import { database, auth } from '../../utils/firebase';

import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';
import Main from '../../components/main/main';
import Header from '../../components/header/header';
import SavedTile from '../../components/savedTile/savedTile';
import Heading from '../../components/heading/heading';
import Loading from '../../components/loading/loading';

import styles from './saved.module.scss';

const Saved = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [hasSaved, setHasSaved] = useState(false);
  const [savedGames, setSavedGames] = useState([]);

  useEffect(() => {
    const savedRef = query(
      ref(database, `users/${auth.currentUser.uid}/saved`),
      orderByChild('edited')
    );

    onValue(savedRef, (snapshot) => {
      const newSaved = [];
      snapshot.forEach((child) => {
        newSaved.push({ id: child.key, data: child.val() });
      });
      if (newSaved.length > 0) {
        setSavedGames(newSaved.reverse());
        setHasSaved(true);
        setLoading(false);
      } else {
        setHasSaved(false);
        setLoading(false);
      }
    });

    return () => {
      off(savedRef);
    };
  }, []);

  const launchQuiz = useCallback(
    (id) =>
      history.push({
        pathname: '/create',
        state: { key: id, launch: true },
      }),
    [history]
  );

  const editQuiz = useCallback(
    (id) =>
      history.push({
        pathname: '/create',
        state: { key: id },
      }),
    [history]
  );

  const duplicateQuiz = useCallback(
    (id) =>
      history.push({
        pathname: '/create',
        state: { key: id, duplicate: true },
      }),
    [history]
  );

  const deleteQuiz = (id) => {
    if (
      window.confirm(
        'Are you sure you want to delete this board? This is an ireversible act.'
      )
    ) {
      const savedRef = ref(
        database,
        `users/${auth.currentUser.uid}/saved/${id}`
      );
      const publicRef = ref(database, `public/${id}`);
      set(savedRef, null);
      set(publicRef, null);
    }
  };

  const items = [];
  savedGames.forEach((board, i) => {
    items.push(
      <SavedTile
        key={`${board.id}`}
        image={board.data.image}
        name={board.data.name}
        lastPlayed={board.data.edited}
        editQuiz={() => {
          editQuiz(board.id);
        }}
        launchQuiz={() => {
          launchQuiz(board.id);
        }}
        duplicateQuiz={() => {
          duplicateQuiz(board.id);
        }}
        deleteQuiz={() => {
          deleteQuiz(board.id);
        }}
      />
    );
  });

  return (
    <Main>
      <Header>
        <Link to={'/'}>
          <Button secondary icon={MdArrowBack}>
            <span className={styles.noMobile}>Back</span>
          </Button>
        </Link>
      </Header>
      <Layout center>
        <div className={styles.content}>
          <div className={styles.title}>
            <Heading className={styles.heading}>Your saved quizes</Heading>
          </div>
          <div className={styles.list}>
            {hasSaved === true ? (
              <div className={styles.grid}>{items}</div>
            ) : (
              <div className={styles.noBoard}>
                You have no saved games, start by creating one
              </div>
            )}
          </div>
          <div className={styles.buttons}>
            <Link
              to={{
                pathname: '/create',
              }}
            >
              <Button>Create a new quiz</Button>
            </Link>
            <Link
              to={{
                pathname: '/explore',
              }}
            >
              <Button secondary>Find a pre-made quiz</Button>
            </Link>
          </div>
        </div>
      </Layout>
      {loading && <Loading>Retrieving your games</Loading>}
    </Main>
  );
};

export default Saved;
