import React, { useContext } from 'react';
import { MdClose, MdVolumeOff, MdVolumeUp } from 'react-icons/md';

import PropTypes from 'prop-types';

import styles from './join.module.scss';
import Main from '../../../components/main/main';
import Button from '../../../components/button/button';
import QRCode from 'react-qr-code';
import Heading from '../../../components/heading/heading';
import Layout from '../../../components/layout/layout';
import { SettingsContext } from '../../../contexts/settings-provider/';

const Join = ({
  room,
  joinedPlayers,
  startGame,
  leaveGame,
  muted,
  toggleMuted,
}) => {
  const [settings] = useContext(SettingsContext);

  const playerList = joinedPlayers.map((player) => {
    return (
      <div key={player.uid} className={styles.player}>
        {player.name}
      </div>
    );
  });

  return (
    <Main background={true}>
      <Layout fullscreen>
        <div className={styles.header}>
          <Button icon={MdClose} secondary onClick={leaveGame} />
          <Button
            icon={muted ? MdVolumeOff : MdVolumeUp}
            secondary
            onClick={toggleMuted}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.qr}>
            <QRCode
              value={`https://${settings.domain}/play?${room}`}
              size={124}
              bgColor={'#ffffff'}
              fgColor={'#222222'}
              level={'L'}
              title={'Join game'}
            />
          </div>
          <p className={styles.instructions}>
            Scan the QR code on your device, or go to <b>{settings.domain}</b>{' '}
            and enter code <b className={styles.code}>{room}</b>
          </p>
          <div className={styles.players}>
            <Heading className={styles.title}>Joined players:</Heading>
            <div className={styles.list}>{playerList}</div>
          </div>
          <Button onClick={startGame} disabled={joinedPlayers.length === 0}>
            {joinedPlayers.length === 0
              ? 'Waiting for players to join'
              : 'Everyone’s in, start the game!'}
          </Button>
        </div>
      </Layout>
    </Main>
  );
};

Join.propTypes = {
  room: PropTypes.string.isRequired,
  joinedPlayers: PropTypes.array,
  startGame: PropTypes.func.isRequired,
  leaveGame: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  toggleMuted: PropTypes.func.isRequired,
};

Join.defaultProps = {
  room: '',
  joinedPlayers: [],
  startGame: null,
  leaveGame: null,
  muted: false,
  toggleMuted: null,
};

export default Join;
