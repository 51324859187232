import React from 'react';
import styles from './profilePic.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MdFace } from 'react-icons/md';

const ProfilePic = ({ image, alt, className, ...props }) => {
  if (image === '1' || !image) {
    return (
      <div className={classNames(styles.image, className)}>
        <MdFace />
      </div>
    );
  }
  return (
    <img
      className={classNames(styles.image, className)}
      src={image}
      alt={alt}
      {...props}
    />
  );
};

ProfilePic.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

ProfilePic.defaultProps = {
  image: null,
  alt: null,
  className: null,
};

export default ProfilePic;
