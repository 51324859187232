import React from 'react';
import styles from './modal.module.scss';
import PropTypes from 'prop-types';

const Modal = ({ children }) => {
  return <div className={styles.modal}>{children} </div>;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  children: null,
};

export default Modal;
