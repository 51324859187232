import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { SettingsContext } from '../../../contexts/settings-provider';

import styles from './results.module.scss';
import Heading from '../../../components/heading/heading';

import classNames from 'classnames';
import Clock from '../../../components/clock/clock';

const Results = ({ scoredPlayers, nextQuestion }) => {
  const [settings] = useContext(SettingsContext);

  const countTime = settings.time.points / 1000;
  const waitTime = settings.time.score / 1000;
  const screenTime = scoredPlayers.length * countTime + waitTime;

  useEffect(() => {
    const timer = setTimeout(() => {
      nextQuestion();
    }, screenTime * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [screenTime, nextQuestion]);

  function sortByScore(a, b) {
    if (a.points < b.points) {
      return 1;
    }
    if (a.points > b.points) {
      return -1;
    }
    return 0;
  }

  const scoreList = scoredPlayers.sort(sortByScore).map((player, i) => {
    return (
      <div className={styles.player} key={player.uid}>
        <div className={styles.name}>{player.name}</div>
        <div className={styles.score}>
          <CountUp
            start={0}
            end={player.points}
            duration={countTime}
            delay={i * countTime}
            decimals={0}
            suffix=" pts"
          />
        </div>
      </div>
    );
  });

  return (
    <div className={classNames(styles.solution, styles.animateIn)}>
      <div className={styles.scores}>
        <Heading className={styles.title}>Scores</Heading>
        <div className={styles.players}>{scoreList}</div>
        <Clock time={screenTime * 1000} />
      </div>
    </div>
  );
};

Results.propTypes = {
  scoredPlayers: PropTypes.array,
  nextQuestion: PropTypes.func,
};

Results.defaultProps = {
  scoredPlayers: [],
  nextQuestion: null,
};

export default Results;
