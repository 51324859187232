import React, { useState, useEffect } from 'react';
import styles from './clock.module.scss';
import PropTypes from 'prop-types';

const Clock = ({ time }) => {
  const [timePercentage, setTimePercentage] = useState(100);

  useEffect(() => {
    const start = Date.now();
    const timer = setInterval(() => {
      const elapsedTime = Date.now() - start;
      if (elapsedTime > time) {
        clearInterval(timer);
      } else {
        const newTimePercentage = (elapsedTime / time) * 100;
        setTimePercentage(newTimePercentage);
      }
    }, 10);
    return () => {
      setTimePercentage(0);
      clearInterval(timer);
    };
  }, [time, setTimePercentage]);

  return (
    <div className={styles.timer}>
      <div
        className={styles.time}
        style={{ width: `${100 - timePercentage}%` }}
      />
    </div>
  );
};

Clock.propTypes = {
  time: PropTypes.number.isRequired,
};

Clock.defaultProps = {
  time: null,
};

export default Clock;
