import React, { useState, useEffect } from 'react';
import { auth } from '../../utils/firebase';
import {
  onAuthStateChanged,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from 'firebase/auth';
import { Redirect, useLocation } from 'react-router-dom';

import Layout from '../../components/layout/layout';
import Heading from '../../components/heading/heading';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import CodeInput from '../../components/codeInput/codeInput';
import SmallButton from '../../components/smallButton/smallButton';
import Header from '../../components/header/header';
import Main from '../../components/main/main';
import Error from '../../components/error/error';
import Loading from '../../components/loading/loading';

import styles from './signIn.module.scss';

const SignIn = () => {
  const location = useLocation();

  const [signedIn, setSignedIn] = useState(false);
  const [currentStep, setCurrentStep] = useState('authenticate');
  const [loginPhone, setLoginPhone] = useState('');
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        setLoading(false);
      }
      unsubscribe();
    });
  }, []);

  const logIn = (phoneNumber) => {
    setLoading(true);

    let validNumber = phoneNumber;
    if (validNumber[0] !== '+') {
      validNumber = '+' + validNumber;
    }

    setLoadingMessage('Verifying phone number');
    signInWithPhoneNumber(auth, validNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setCurrentStep('verify');

        setLoginPhone(validNumber);
        setLoading(false);
        setAuthError(null);
      })
      .catch((error) => {
        console.log(error);
        setAuthError('Invalid number');
        setLoading(false);
      });
  };

  const verifyCode = (code) => {
    setLoading(true);
    setLoadingMessage('Checking code');
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        setAuthError(null);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setSignedIn(true);
          }
        });
        return () => {
          unsubscribe();
        };
      })
      .catch((error) => {
        console.log(error);
        setAuthError('Wrong code');
        setLoading(false);
      });
  };

  const resendCode = () => {
    setCurrentStep('authenticate');
    setLoginPhone('null');
  };

  if (signedIn === true && location.pathname === '/sign-in') {
    return <Redirect to="/" />;
  }

  return (
    <Main>
      <Header showBackButton />
      <Layout center>
        <div className={styles.content}>
          {currentStep === 'authenticate' && (
            <Authenticate logIn={logIn} error={authError} />
          )}
          {currentStep === 'verify' && (
            <Verify
              phone={loginPhone}
              verifyCode={verifyCode}
              resendCode={resendCode}
              error={authError}
            />
          )}
          {loading === true && <Loading message={loadingMessage} />}
        </div>
      </Layout>
    </Main>
  );
};

const Authenticate = ({ logIn, error }) => {
  const [validPhone, setValidPhone] = useState(false);
  const [countryCode, setCountryCode] = useState('+47');
  const [phoneNumber, setPhoneNumber] = useState('');

  const phoneLength = 8;

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {},
      },
      auth
    );
  }, []);

  useEffect(() => {
    if (phoneNumber.length === phoneLength && countryCode.length > 1) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
  }, [phoneNumber, countryCode]);

  return (
    <div className={styles.container}>
      {error && <Error>{error}</Error>}
      <Heading className={styles.heading}>Sign in with your phone</Heading>
      <p>Sign in or create an account with your phone-number</p>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (validPhone === true) {
            logIn(`${countryCode}${phoneNumber}`);
          }
          return false;
        }}
      >
        <div className={styles.phone}>
          <Input
            className={styles.country}
            centered
            numbersOnly
            maxLength={4}
            placeholder={'+47'}
            value={countryCode}
            inputMode="numeric"
            onChange={setCountryCode}
          />
          <Input
            className={styles.number}
            centered
            numbersOnly
            maxLength={phoneLength}
            value={phoneNumber}
            placeholder={'99 99 99 99'}
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={setPhoneNumber}
          />
        </div>
        <Button disabled={!validPhone} id={'sign-in-button'}>
          Sign in
        </Button>
      </form>
    </div>
  );
};

const Verify = ({ phone, verifyCode, resendCode, error }) => {
  const [validCode, setValidCode] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');

  const codeLength = 6;

  useEffect(() => {
    if (phoneCode.length === codeLength) {
      setValidCode(true);
    } else {
      setValidCode(false);
    }
  }, [phoneCode]);

  useEffect(() => {
    if (validCode === true && !error) {
      verifyCode(phoneCode);
    }
  }, [validCode, verifyCode, phoneCode, error]);

  return (
    <div className={styles.container}>
      {error && <Error>{error}</Error>}
      <Heading className={styles.heading}>Verify {phone}</Heading>
      <p>You will recieve a {codeLength} digit code on SMS shortly</p>
      <div className={styles.form}>
        <CodeInput length={codeLength} onChange={setPhoneCode} />
        <div className={styles.buttons}>
          <Button
            disabled={!validCode}
            onClick={() => {
              verifyCode(phoneCode);
            }}
          >
            Verify
          </Button>
          <SmallButton
            onClick={() => {
              resendCode(phone);
            }}
          >
            Didnt work? Try again
          </SmallButton>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
