import React from 'react';
import PropTypes from 'prop-types';

import styles from './introduction.module.scss';
import Heading from '../../../components/heading/heading';
import SmallButton from '../../../components/smallButton/smallButton';
import Button from '../../../components/button/button';
import Modal from '../../../components/modal/modal';
import classNames from 'classnames';

const Introduction = ({ metaData, totalQuestions, startQuiz, backToJoin }) => {
  return (
    <Modal>
      <div className={classNames(styles.introduction, styles.animateIn)}>
        {metaData.image && (
          <div className={styles.image}>
            <img src={metaData.image} alt={metaData.title} />
          </div>
        )}
        <div className={styles.text}>
          <p className={styles.questions}>{totalQuestions} questions</p>
          <Heading className={styles.title}>{metaData.title}</Heading>
          <p className={styles.description}>{metaData.description}</p>
          <div className={styles.actions}>
            <SmallButton onClick={backToJoin}>Back</SmallButton>
            <Button onClick={startQuiz}>Start</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

Introduction.propTypes = {
  metaData: PropTypes.object,

  totalQuestions: PropTypes.number,
  startQuiz: PropTypes.func.isRequired,
  backToJoin: PropTypes.func.isRequired,
};

Introduction.defaultProps = {
  metaData: null,
  totalQuestions: 0,
  startQuiz: null,
  backToJoin: null,
};

export default Introduction;
