import React, { useRef, useState, useEffect } from 'react';
import styles from './codeInput.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CodeInput = ({ length, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const inputRef = useRef(null);

  const indicators = [];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  for (let i = 0; i < length; i++) {
    indicators.push(
      <div
        key={`indicator${i}`}
        className={classNames(styles.indcator, {
          [styles.active]: i === currentValue.length && isFocused === true,
        })}
      >
        {currentValue[i]}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.code}>
        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder=""
          autoComplete="one-time-code"
          maxLength={length}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
            setCurrentValue(value);
            if (value.length === length) {
              e.target.blur();
            }
          }}
          onFocus={(e) => {
            setIsFocused(true);
            e.target.value = '';
            setCurrentValue('');
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        {indicators}
      </div>
    </div>
  );
};

CodeInput.propTypes = {
  length: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

CodeInput.defaultProps = {
  length: null,
  onChange: null,
};

export default CodeInput;
