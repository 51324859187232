import coordinatesToMeters from './coodrinatesToMeters';

const scoreAnswer = (answer, correct, timeSpent, timeTotal) => {
  const timeMultiplier = Math.max(1 - timeSpent / timeTotal, 0.75);

  const points = Math.max(
    Math.exp(Math.max(1 - coordinatesToMeters(answer, correct) / 2400000), 0) *
      1000,
    0
  );

  return Math.round(points * timeMultiplier);
};

export default scoreAnswer;
