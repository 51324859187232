import React from 'react';
import styles from './slider.module.scss';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';

const Slider = ({ min, max, minValue, maxValue, step, onChange, ...props }) => {
  return (
    <div className={styles.container}>
      <ReactSlider
        className={styles.horizontalSlider}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        min={min}
        max={max}
        step={step}
        value={[minValue, maxValue]}
        minDistance={step}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        {...props}
      />
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

Slider.defaultProps = {
  min: 1,
  max: 1000,
  minValue: 100,
  maxValue: 900,
  step: 1,
  onChange: null,
};

export default Slider;
