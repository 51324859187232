import React from 'react';
import styles from './main.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Main = ({ background, children }) => {
  return (
    <main
      className={classNames(styles.main, {
        [styles.background]: background === true,
      })}
    >
      {children}
    </main>
  );
};

Main.propTypes = {
  background: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Main.defaultProps = {
  background: false,
  children: null,
};

export default Main;
