import answered from '../assets/sound/answered.mp3';
import join from '../assets/sound/join.mp3';
import music from '../assets/sound/music.mp3';
import round from '../assets/sound/round.mp3';
import gameEnd from '../assets/sound/end.mp3';
import roundEnd from '../assets/sound/roundEnd.mp3';
import roundStart from '../assets/sound/roundStart.mp3';

const playSound = (sound) => {
  switch (sound) {
    case 'answered':
      const answeredTracks = [answered];
      playTrack(sound, answeredTracks);
      break;
    case 'join':
      const joinedTracks = [join];
      playTrack(sound, joinedTracks);
      break;
    case 'music':
      const musicTracks = [music];
      playTrack(sound, musicTracks);
      break;
    case 'round':
      const roundTracks = [round];
      playTrack(sound, roundTracks);
      break;
    case 'roundEnd':
      const roundEndTracks = [roundEnd];
      playTrack(sound, roundEndTracks);
      break;
    case 'end':
      const gameEndTracks = [gameEnd];
      playTrack(sound, gameEndTracks);
      break;
    case 'roundStart':
      const roundStartTracks = [roundStart];
      playTrack(sound, roundStartTracks);
      break;

    default:
      console.log(`Unknown sound ${sound}`);
      break;
  }
};

let prevEvent = '';
let prevTrack = -1;

const playTrack = (event, tracks) => {
  const getRandomTrack = () => {
    return Math.round(Math.random() * Math.max(tracks.length - 1, 0));
  };

  let trackIndex = getRandomTrack();
  if (tracks.length > 1 && event === prevEvent) {
    while (trackIndex === prevTrack) {
      trackIndex = getRandomTrack();
    }
  }

  prevEvent = event;
  prevTrack = trackIndex;
  const track = tracks[trackIndex];
  let audio = new Audio(track);
  audio.play();
};

export default playSound;
