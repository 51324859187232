import React from 'react';
import styles from './button.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ icon, secondary, small, disabled, children, ...props }) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.secondary]: secondary === true,
        },
        {
          [styles.small]: small === true,
        },
        {
          [styles.onlyIcon]: !children,
        }
      )}
      disabled={disabled}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon()}</div>}
      {children}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.func,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  icon: null,
  secondary: false,
  small: false,
  disabled: false,
  children: null,
};

export default Button;
