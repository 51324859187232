import React from 'react';

import styles from './question.module.scss';
import PropTypes from 'prop-types';

import Heading from '../../../components/heading/heading';
import Clock from '../../../components/clock/clock';
import Button from '../../../components/button/button';
import Modal from '../../../components/modal/modal';
import AnswerMap from '../../../components/answerMap/answerMap';
import classNames from 'classnames';

const Question = ({
  title,
  time,
  position,
  setPosition,
  answered,
  setAnswered,
  submitAnswer,
  hardcore,
  theme,
}) => {
  const timeToEnd = time;

  return (
    <div className={styles.question}>
      <AnswerMap setPosition={setPosition} hardcore={hardcore} theme={theme} />
      {answered === false && (
        <div className={styles.answer}>
          <div
            className={classNames(
              styles.header,
              styles.animateIn,
              styles.animateTop
            )}
          >
            <div className={styles.question}>
              <Heading className={styles.title}>{title}</Heading>
              <Clock time={timeToEnd} />
            </div>
          </div>
          <div className={classNames(styles.footer, styles.animateIn)}>
            <Button
              onClick={() => {
                setAnswered(true);
                submitAnswer(position.lat, position.lng);
              }}
            >
              Lock pin
            </Button>
          </div>
        </div>
      )}
      {answered === true && (
        <Modal>
          <div className={classNames(styles.modal, styles.animateIn)}>
            <Heading className={styles.title}>Good work!</Heading>
            <p>Waiting for question to end</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

Question.propTypes = {
  title: PropTypes.string,
  time: PropTypes.number,
  position: PropTypes.object,
  setPosition: PropTypes.func,
  answered: PropTypes.bool,
  setAnswered: PropTypes.func,
  submitAnswer: PropTypes.func,
  hardcore: PropTypes.bool,
  theme: PropTypes.number,
};

Question.defaultProps = {
  room: '',
  time: 10000,
  position: { lat: 0, lng: 0 },
  setPosition: null,
  answered: false,
  setAnswered: null,
  submitAnswer: null,
  hardcore: false,
  theme: 0,
};

export default Question;
