/* eslint-disable no-case-declarations */
const defaultSettings = {
  user: false,
  domain: 'mapply.app',
  time: {
    points: 2000,
    score: 5000,
    question: 30000,
  },
};

export const reducer = (settings, action) => {
  switch (action.type) {
    case 'update_settings':
      const updatedSettings = {
        ...settings,
        [action.payload.key]: action.payload.value,
      };
      return updatedSettings;
    case 'update_user':
      const updatedUser = {
        ...settings,
        user: action.payload,
      };
      return updatedUser;
    default:
      return settings;
  }
};

export const initialState = () => {
  return defaultSettings;
};
