import React, { useRef, useEffect, useCallback } from 'react';
import styles from './searchMap.module.scss';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { MdOutlineLocationOn } from 'react-icons/md';

import PropTypes from 'prop-types';
import Button from '../button/button';

mapboxgl.accessToken =
  'pk.eyJ1Ijoibm90ZXRoZW5vdGUiLCJhIjoiY2t3ZGZjd3RmMXV2cTJvcW1wcm5mczg3ZCJ9.b4fGC2AoXtvBLqgSwX4oew';

const SearchMap = ({ position, setPosition, startZoom, endZoom }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const searchMarker = useRef(null);

  const addPin = useCallback(
    (pinPosition) => {
      if (searchMarker.current) {
        searchMarker.current.remove();
      }
      const solutionPin = document.createElement('div');
      solutionPin.className = styles.marker;
      searchMarker.current = new mapboxgl.Marker({
        element: solutionPin,
        draggable: true,
        color: 'red',
      })
        .setLngLat(pinPosition)
        .addTo(map.current)
        .on('dragend', () => {
          const markerPos = searchMarker.current.getLngLat();
          setPosition(markerPos);
        });
    },
    [setPosition]
  );

  const centerMap = useCallback(
    (zoom) => {
      map.current.easeTo({
        center: [position.lng, position.lat],
        zoom: zoom ? zoom : map.current.getZoom(),
        speed: 0.2,
        curve: 1,
        duration: 200,
      });
    },
    [position]
  );

  const pinCenter = useCallback(() => {
    const mapCenter = map.current.getCenter();
    setPosition(mapCenter);
    addPin(mapCenter);
  }, [addPin, setPosition]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: 0,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: 'Search',
      flyTo: { maxDuration: 100, screenSpeed: 1000 },
    });
    geocoder.on('result', function (e) {
      //const searchPos = e.result.center;
      //setPosition({ lng: searchPos[0], lat: searchPos[1] });
      geocoder.clear();
      //addPin(searchPos);
    });
    map.current.addControl(geocoder);
  }, [addPin, setPosition]);

  useEffect(() => {
    const currentPosition = map.current.getCenter();
    if (
      currentPosition.lat !== position.lat ||
      currentPosition.lng !== position.lng
    ) {
      addPin([position.lng, position.lat]);
      centerMap();
    }
  }, [position, centerMap, addPin]);

  useEffect(() => {
    map.current.setZoom(endZoom);
    centerMap();
  }, [endZoom]);

  useEffect(() => {
    map.current.setZoom(startZoom);
    centerMap();
  }, [startZoom]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.map} ref={mapContainer}></div>
      <div className={styles.button}>
        <Button small onClick={pinCenter} icon={MdOutlineLocationOn}>
          Set pin
        </Button>
      </div>
    </div>
  );
};

SearchMap.propTypes = {
  position: PropTypes.object,
  setPosition: PropTypes.func,
  startZoom: PropTypes.number,
  endZoom: PropTypes.number,
};

SearchMap.defaultProps = {
  position: { lat: 0, lng: 0 },
  positsetPositionion: null,
  startZoom: 0,
  endZoom: 0,
};

export default SearchMap;
