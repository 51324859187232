import formatTime from './formatTime';

const formatDate = (timestamp) => {
  var stampDate = formatTime(new Date(timestamp * 1000));
  var nowDate = formatTime(new Date());

  if (
    stampDate.day === nowDate.day &&
    stampDate.month === nowDate.month &&
    stampDate.year === nowDate.year
  ) {
    return `${stampDate.hour}:${stampDate.min}`;
  } else {
    return `${stampDate.day}.${stampDate.month}.${stampDate.year}`;
  }
};

export default formatDate;
