const formatTime = (date) => {
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  var milli = date.getMilliseconds();
  return {
    year: date.getFullYear(),
    month: (month < 10 ? '0' : '') + month,
    day: (day < 10 ? '0' : '') + day,
    hour: (hour < 10 ? '0' : '') + hour,
    min: (min < 10 ? '0' : '') + min,
    sec: (sec < 10 ? '0' : '') + sec,
    milli: Math.floor(((sec < 10 ? '0' : '') + milli) / 10),
  };
};

export default formatTime;
