import React from 'react';
import PropTypes from 'prop-types';

import styles from './lobby.module.scss';
import Main from '../../../components/main/main';
import Layout from '../../../components/layout/layout';

import Heading from '../../../components/heading/heading';
import loadingAnim from '../../../assets/loading.mp4';

const Lobby = ({ text }) => {
  return (
    <Main>
      <Layout fullscreen>
        <div className={styles.lobby}>
          <video
            playsInline
            muted
            autoPlay
            loop
            src={loadingAnim}
            className={styles.animation}
          />
          <Heading className={styles.title}>{text}</Heading>
        </div>
      </Layout>
    </Main>
  );
};

Lobby.propTypes = {
  text: PropTypes.string,
};

Lobby.defaultProps = {
  text: '',
};

export default Lobby;
