const randomCode = (length) => {
  let code = '';
  const chars = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  for (var i = length ? length : 6; i > 0; i--) {
    code += chars[Math.floor(Math.random() * chars.length)];
  }
  return code;
};

export default randomCode;
