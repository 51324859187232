import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/layout';
import Heading from '../../components/heading/heading';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import Main from '../../components/main/main';

import styles from './frontpage.module.scss';
import Header from '../../components/header/header';

const FrontPage = () => {
  const [roomKey, setRoomKey] = useState('');
  const [validRoomKey, setValidRoomKey] = useState('');

  useEffect(() => {
    if (roomKey.length > 0) {
      setValidRoomKey(true);
    } else {
      setValidRoomKey(false);
    }
  }, [roomKey]);

  return (
    <Main>
      <Header />
      <Layout center>
        <div className={styles.hero} />
        <div className={styles.content}>
          <div className={styles.container}>
            <Heading className={styles.heading}>Join a game</Heading>
            <div className={styles.form}>
              <Input
                centered
                uppercase
                maxLength={12}
                placeholder={'Room code'}
                onChange={setRoomKey}
              />
              <Link
                to={{
                  pathname: '/play',
                  state: { room: roomKey.toUpperCase() },
                }}
              >
                <Button disabled={!validRoomKey}>Join</Button>
              </Link>
            </div>
          </div>
          <div className={styles.container}>
            <Heading className={styles.heading}>Or create new game</Heading>
            <Link
              to={{
                pathname: '/saved',
              }}
            >
              <Button>Create new game</Button>
            </Link>
          </div>
        </div>
      </Layout>
    </Main>
  );
};

export default FrontPage;
