const getRandomMotivation = (points) => {
  let motivations = ['Keep it up buttercup!', 'Wow, you rock!'];
  if (points < 300) {
    motivations = ['You got to step it up', 'We all make mistakes'];
  }
  const randomIndex = Math.floor(Math.random() * motivations.length);
  return motivations[randomIndex];
};

export default getRandomMotivation;
