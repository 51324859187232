import React, { useState, useEffect } from 'react';

import styles from './end.module.scss';
import PropTypes from 'prop-types';
import { MdStar } from 'react-icons/md';

import Heading from '../../../components/heading/heading';
import Main from '../../../components/main/main';
import Layout from '../../../components/layout/layout';
import placeNumberToString from '../../../utils/placeNumberToString';
import Button from '../../../components/button/button';
import classNames from 'classnames';

const End = ({ place, submitRating, exitQuiz }) => {
  const [rating, setRating] = useState(0);

  const updateRating = (newRating) => {
    if (newRating === rating) {
      setRating(0);
    } else {
      setRating(newRating);
    }
  };

  useEffect(() => {
    submitRating(rating);
  }, [rating, submitRating]);

  const starList = [];
  for (let i = 1; i <= 5; i++) {
    starList.push(
      <button
        key={`star-${i}`}
        className={classNames(styles.star, {
          [styles.active]: rating >= i,
        })}
        onClick={() => {
          updateRating(i);
        }}
      >
        <MdStar />
      </button>
    );
  }

  return (
    <Main>
      <Layout>
        <div className={styles.points}>
          <div className={styles.ranking}>
            <Heading className={styles.title}>
              You came in {placeNumberToString(place)} place!
            </Heading>
            <p className={styles.motivation}>
              {place >= 3 ? 'Better luck next time!' : 'Good work champ!'}
            </p>
          </div>
          <div className={styles.ranking}>
            <Heading className={styles.prompt}>Rate this quiz:</Heading>
            <div className={styles.stars}>{starList}</div>
          </div>
          <Button onClick={exitQuiz}>Exit quiz</Button>
        </div>
      </Layout>
    </Main>
  );
};

End.propTypes = {
  place: PropTypes.number,
  submitRating: PropTypes.func,
  exitQuiz: PropTypes.func,
};

End.defaultProps = {
  place: 1,
  submitRating: null,
  exitQuiz: null,
};

export default End;
