const placeNumberToString = (number) => {
  switch (number) {
    case 0:
      return false;
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};
export default placeNumberToString;
