const getMapTheme = (hardcore, theme) => {
  if (theme === 0) {
    if (hardcore === true) {
      return 'mapbox://styles/notethenote/ckuwmpvt90rb217qq0cb0q8t0';
    } else {
      return 'mapbox://styles/mapbox/streets-v11';
    }
  } else if (theme === 1) {
    if (hardcore === true) {
      return 'mapbox://styles/notethenote/ckwp5bei58pji14p3ti070fx2';
    } else {
      return 'mapbox://styles/notethenote/ckwv8dehuem8515p2fwig1xyo';
    }
  } else if (theme === 2) {
    if (hardcore === true) {
      return 'mapbox://styles/notethenote/ckwv8gu7zenc114p4l5vmyhcg';
    } else {
      return 'mapbox://styles/notethenote/ckwv8fuah40f415t7ia594aea';
    }
  }
  return 'mapbox://styles/mapbox/streets-v11';
};

export default getMapTheme;
