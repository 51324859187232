import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

import { ref, onValue, off, query } from 'firebase/database';

import { database } from '../../utils/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';
import Main from '../../components/main/main';
import Header from '../../components/header/header';
import Heading from '../../components/heading/heading';
import Loading from '../../components/loading/loading';

import styles from './explore.module.scss';
import PublicTile from '../../components/publicTile/publicTile';

const Explore = () => {
  const history = useHistory();
  const functions = getFunctions();

  const [loading, setLoading] = useState(true);
  const [savedGames, setSavedGames] = useState([]);

  useEffect(() => {
    const savedRef = query(ref(database, `public`));

    onValue(savedRef, (snapshot) => {
      const newSaved = [];
      snapshot.forEach((child) => {
        newSaved.push({ id: child.key, data: child.val() });
      });
      if (newSaved.length > 0) {
        setSavedGames(newSaved.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    return () => {
      off(savedRef);
    };
  }, []);

  const startQuiz = (savedKey) => {
    setLoading(true);
    const createNewRoom = httpsCallable(functions, 'createNewRoom');
    createNewRoom({ game: savedKey, public: true }).then((result) => {
      const data = result.data;
      launchQuiz(data);
    });
  };

  const launchQuiz = useCallback(
    (code) => {
      history.push({
        pathname: '/host',
        state: { room: code },
      });
    },
    [history]
  );

  const publicGames = savedGames.map((game) => {
    return (
      <PublicTile
        key={game.id}
        name={game.data.name}
        description={game.data.description}
        image={game.data.image}
        startQuiz={() => {
          startQuiz(game.id);
        }}
      />
    );
  });

  return (
    <Main>
      <Header>
        <Link to={'/saved'}>
          <Button secondary icon={MdArrowBack}>
            <span className={styles.noMobile}>Back</span>
          </Button>
        </Link>
      </Header>
      <Layout center>
        <div className={styles.content}>
          <div className={styles.title}>
            <Heading className={styles.heading}>Public games</Heading>
          </div>
          <div className={styles.list}>
            <div className={styles.grid}>{publicGames}</div>
          </div>
        </div>
      </Layout>
      {loading && <Loading>Loading</Loading>}
    </Main>
  );
};

export default Explore;
