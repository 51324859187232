import React from 'react';
import styles from './inputLabel.module.scss';
import PropTypes from 'prop-types';

const InputLabel = ({ title, description }) => {
  if (title || description) {
    return (
      <div className={styles.text}>
        {title && <label className={styles.title}>{title}</label>}
        {description && <p className={styles.description}>{description}</p>}
      </div>
    );
  }
  return null;
};

InputLabel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

InputLabel.defaultProps = {
  title: null,
  description: null,
};

export default InputLabel;
