import React from 'react';
import styles from './profileLink.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ProfilePic from '../profilePic/profilePic';

const ProfileLink = ({ name, image }) => {
  return (
    <Link
      to={{
        pathname: '/profile',
      }}
    >
      <div className={styles.link}>
        <span className={styles.name}>{name}</span>
        <ProfilePic className={styles.image} image={image} alt={name} />
      </div>
    </Link>
  );
};

ProfileLink.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
};

ProfileLink.defaultProps = {
  name: null,
  image: null,
};

export default ProfileLink;
