import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import styles from './header.module.scss';
import PropTypes from 'prop-types';
import Button from '../button/button';
import ProfileLink from '../profileLink/profileLink';
import Logo from '../logo/logo';
import SmallButton from '../smallButton/smallButton';

import { MdArrowBack } from 'react-icons/md';
import { auth } from '../../utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const Header = ({ showBackButton, children }) => {
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscirbe = onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setUser({
          name: auth.currentUser.displayName,
          image: auth.currentUser.photoURL,
        });
        setSignedIn(true);
      } else {
        setSignedIn(false);
      }
    });
    return () => {
      unsubscirbe();
    };
  }, []);

  return (
    <header className={styles.header}>
      <div>{children ? children : <Logo />}</div>
      <div>
        <Menu showBackButton={showBackButton} signedIn={signedIn} user={user} />
      </div>
    </header>
  );
};

const Menu = ({ showBackButton, signedIn, user }) => {
  let history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  if (showBackButton === true) {
    return (
      <Button
        icon={MdArrowBack}
        onClick={() => {
          goBack();
        }}
      >
        <span className={styles.noMobile}>Back</span>
      </Button>
    );
  } else if (signedIn === true) {
    return <ProfileLink name={user.name} image={user.image} />;
  } else if (signedIn === false) {
    return (
      <Link to="/profile">
        <SmallButton>Sign in</SmallButton>
      </Link>
    );
  } else {
    return null;
  }
};

Header.propTypes = {
  showBackButton: PropTypes.bool,
  user: PropTypes.object,
  children: PropTypes.node,
};

Header.defaultProps = {
  showBackButton: false,
  user: null,
  children: null,
};

export default Header;
