import React from 'react';
import styles from './logo.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Logo = ({ className, ...props }) => {
  return (
    <Link to={'/'}>
      <div className={classNames(styles.logo, className)} {...props}>
        Mapply
      </div>
    </Link>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
};

export default Logo;
