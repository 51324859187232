import React, { useState } from 'react';

import styles from './points.module.scss';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import Heading from '../../../components/heading/heading';
import Main from '../../../components/main/main';
import Layout from '../../../components/layout/layout';
import placeNumberToString from '../../../utils/placeNumberToString';
import getRandomMotivation from '../../../utils/getRandomMotivation';
import formatSeconds from '../../../utils/formatSeconds';
import classNames from 'classnames';
import formatMeters from '../../../utils/formatMeters';
import videoSrc from '../../../assets/miss.mp4';

const Points = ({ place, meters, time, points }) => {
  const [motivation] = useState(getRandomMotivation(points));
  const formatedMeters = formatMeters(meters);
  return (
    <Main>
      <Layout>
        {points < 300 && (
          <video
            autoPlay
            muted
            playsInline
            src={videoSrc}
            className={styles.video}
          />
        )}
        <div className={styles.points}>
          <div className={styles.ranking}>
            {placeNumberToString(place) ? (
              <Heading className={styles.title}>
                You're in {placeNumberToString(place)} place!
              </Heading>
            ) : (
              <Heading>You can do this!</Heading>
            )}
            <p className={styles.motivation}>{motivation}</p>
          </div>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.number}>
                <CountUp
                  start={0}
                  end={formatedMeters.value}
                  duration={1}
                  decimals={0}
                />
              </div>
              <div className={styles.name}>{formatedMeters.unit}</div>
            </div>
            <div className={styles.stat}>
              <div className={styles.number}>
                {formatSeconds(time.answer - time.question)}
              </div>
              <div className={styles.name}>seconds</div>
            </div>
          </div>
          {points !== 0 && (
            <div className={classNames(styles.score, styles.animateIn)}>
              <CountUp
                start={0}
                end={points}
                duration={1}
                delay={1}
                decimals={0}
                suffix=" points"
                prefix="+"
              />
            </div>
          )}
        </div>
      </Layout>
    </Main>
  );
};

Points.propTypes = {
  place: PropTypes.number,
  meters: PropTypes.number,
  time: PropTypes.object,
  points: PropTypes.number,
};

Points.defaultProps = {
  place: 1,
  meters: 10000,
  time: null,
  points: 0,
};

export default Points;
