import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { auth } from './utils/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { SettingsContext } from './contexts/settings-provider';

import FrontPage from './pages/frontpage/frontpage';
import Explore from './pages/explore/explore';
import Profile from './pages/profile/profile';
import Saved from './pages/saved/saved';
import SignIn from './pages/signIn/signIn';
import Create from './pages/create/create';
import Display from './pages/display/display';
import Play from './pages/play/play';
import Test from './pages/test';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/test">
          <Test />
        </Route>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <Route path="/profile">
          <AuthValidation>
            <Profile />
          </AuthValidation>
        </Route>
        <Route path="/saved">
          <AuthValidation>
            <Saved />
          </AuthValidation>
        </Route>
        <Route path="/create">
          <AuthValidation>
            <Create />
          </AuthValidation>
        </Route>
        <Route path="/host">
          <AuthValidation>
            <Display />
          </AuthValidation>
        </Route>
        <Route path="/explore">
          <AuthValidation>
            <Explore />
          </AuthValidation>
        </Route>
        <Route path="/play">
          <Play />
        </Route>
        <Route path="/">
          <FrontPage />
        </Route>
      </Switch>
    </Router>
  );
}

const AuthValidation = ({ children }) => {
  const [settings, dispatch] = useContext(SettingsContext);

  const [signedIn, setSignedIn] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [anonymous, setAnonymous] = useState(false);

  useEffect(() => {
    const unsubsrcibe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setSignedIn(true);
        if (user.isAnonymous) {
          setAnonymous(true);
          signOut(auth);
        }
        if (user.displayName === null) {
          setRegistered(false);
        } else {
          setRegistered(true);
          dispatch({ type: 'update_user', payload: user });
        }
      } else {
        setSignedIn(false);
        dispatch({ type: 'update_user', payload: false });
      }
    });
    return () => {
      unsubsrcibe();
    };
  }, [settings.user, dispatch]);

  if (signedIn === true && registered === true) {
    return children;
  } else if (signedIn === false || anonymous === true) {
    return <SignIn />;
  } else if (signedIn === true && registered === false) {
    return <Profile />;
  }
};

export default App;
