import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SettingsContext } from '../../contexts/settings-provider';
import {
  signInAnonymously,
  updateProfile,
  onAuthStateChanged,
} from 'firebase/auth';
import { database, auth } from '../../utils/firebase';

import {
  ref,
  set,
  get,
  onValue,
  off,
  serverTimestamp,
  orderByValue,
  query,
} from 'firebase/database';

import coordinatesToMeters from '../../utils/coodrinatesToMeters';

import Loading from '../../components/loading/loading';
import Join from './join/join';
import Lobby from './lobby/lobby';
import Question from './question/question';
import Points from './points/points';
import End from './end/end';
import scoreAnswer from '../../utils/scoreAnswer';

const Play = () => {
  const history = useHistory();

  const location = useLocation();
  const [settings, dispatch] = useContext(SettingsContext);
  const [playStage, setPlayStage] = useState('join');
  const [loading, setLoading] = useState(true);

  const [joinedGame, setJoinedGame] = useState(false);

  const [error, setError] = useState('');
  const [name, setName] = useState(location.state?.room);
  const [roomCode, setRoomCode] = useState(location.state?.room.toUpperCase());
  const [validRoom, setValidRoom] = useState(false);
  const [validName, setValidName] = useState(false);

  const [questions, setQuestions] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionTime, setQuestionTime] = useState(0);
  const [answerTime, setAnswerTime] = useState(0);

  const [hardcore, setHardcore] = useState(false);
  const [theme, setTheme] = useState(0);

  const [place, setPlace] = useState(0);
  const [distance, setDistance] = useState(0);
  const [points, setPoints] = useState(0);

  const [answered, setAnswered] = useState(false);
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const submitAnswer = useCallback(
    (lat, lng) => {
      setDistance(
        coordinatesToMeters({ lat: lat, lng: lng }, currentQuestion.location)
      );
      setAnswerTime(Date.now());

      const scoredPoints = scoreAnswer(
        { lat: lat, lng: lng },
        currentQuestion.location,
        Date.now() - questionTime,
        settings.time.question
      );
      setPoints(scoredPoints);

      const answerRef = ref(
        database,
        `games/${roomCode}/answers/${currentQuestionIndex}/${auth.currentUser.uid}`
      );
      set(answerRef, {
        name: auth.currentUser.displayName,
        timestamp: serverTimestamp(),
        answer: {
          lat: lat,
          lng: lng,
        },
      });
    },
    [
      currentQuestion,
      currentQuestionIndex,
      roomCode,
      questionTime,
      settings.time.question,
    ]
  );

  const checkAnswered = useCallback(() => {
    if (answered === false) {
      submitAnswer(position.lat, position.lng);
    }
  }, [answered, position, submitAnswer]);

  const submitRating = (rating) => {
    const validRating = Math.floor(Math.max(Math.min(5, rating), 0));
    const ratingRef = ref(
      database,
      `games/${roomCode.toUpperCase()}/ratings/${auth.currentUser.uid}`
    );

    if (validRating === 0) {
      set(ratingRef, null);
    } else {
      set(ratingRef, validRating);
    }
  };

  const exitQuiz = useCallback(() => history.push('/'), [history]);

  //Get room code from link
  useEffect(() => {
    const linkCode = location.search.replace('?', '').toUpperCase();
    if (linkCode.length >= 1) {
      setRoomCode(linkCode);
    }
  }, [location]);

  // Listen to change in auth and get userName
  useEffect(() => {
    const unsubsrcibe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setName(user.displayName);
        dispatch({ type: 'update_user', payload: user });
        setLoading(false);
      } else {
        signInAnonymously(auth)
          .then(() => {
            dispatch({ type: 'update_user', payload: user });
            setLoading(false);
          })
          .catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);
          });
      }
    });
    return () => {
      unsubsrcibe();
    };
  }, [dispatch]);

  const setDisplayName = (newName) => {
    setLoading(true);
    setName(newName);
    updateProfile(auth.currentUser, {
      displayName: newName,
    }).then(() => {
      dispatch({ type: 'update_user', payload: auth.currentUser });
      setLoading(false);
    });
  };

  // Check if roomcode is valid
  useEffect(() => {
    if (!loading) {
      if (roomCode) {
        const roomRef = ref(database, `games/${roomCode}`);
        get(roomRef).then((snapshot) => {
          const roomData = snapshot.val();
          if (roomData) {
            setValidRoom(true);
          } else {
            setError('Not a valid room');
          }
        });
        if (!roomCode) {
          setValidRoom(false);
        }
      }
    }
  }, [roomCode, loading]);

  // Check if name is valid
  useEffect(() => {
    if (!loading) {
      if (!name) {
        setValidName(false);
      } else {
        setValidName(true);
      }
    }
  }, [name, loading]);

  // Check if form is valid and join
  useEffect(() => {
    if (validRoom && validName && !loading) {
      joinRoom(roomCode, auth.currentUser.displayName);
    }
  }, [playStage, validRoom, validName, roomCode, loading]);

  // Join room
  const joinRoom = (room, displayName) => {
    const playerRef = ref(
      database,
      `games/${room.toUpperCase()}/players/${auth.currentUser.uid}`
    );
    set(playerRef, displayName);
    setRoomCode(room);
    setJoinedGame(true);
  };

  useEffect(() => {
    const questionsRef = ref(database, `games/${roomCode}/questions`);
    onValue(questionsRef, (snapshot) => {
      const newQuestions = snapshot.val();
      setQuestions(newQuestions);
    });
  }, [roomCode]);

  useEffect(() => {
    const settingsRef = ref(database, `games/${roomCode}/settings`);
    onValue(settingsRef, (snapshot) => {
      const newSettings = snapshot.val();
      if (newSettings) {
        setHardcore(newSettings?.hard === true ? true : false);
        setTheme(newSettings?.theme);
      }
    });
  }, [roomCode]);

  useEffect(() => {
    const questionIndex = ref(database, `games/${roomCode}/currentQuestion`);
    onValue(questionIndex, (snapshot) => {
      const newCurrentQuestion = snapshot.val();
      setAnswered(false);
      setCurrentQuestionIndex(newCurrentQuestion);
    });
    return () => {
      off(questionIndex);
    };
  }, [roomCode]);

  useEffect(() => {
    if (questions) {
      setCurrentQuestion(questions[currentQuestionIndex]);
    }
  }, [questions, currentQuestionIndex]);

  useEffect(() => {
    const statusRef = ref(database, `games/${roomCode}/status`);
    onValue(statusRef, (snapshot) => {
      const newStatus = snapshot.val();
      if (joinedGame === true) {
        if (newStatus === 'lobby') {
          setPlayStage('lobby');
        } else if (newStatus === 'intro') {
          setPlayStage('waiting');
        } else if (newStatus === 'question') {
          setPlayStage('question');
        } else if (newStatus === 'results') {
          checkAnswered();
          setPlayStage('points');
        } else if (newStatus === 'end') {
          setPlayStage('end');
        }
      }
    });
    return () => {
      off(statusRef);
    };
  }, [roomCode, joinedGame, checkAnswered]);

  useEffect(() => {
    if (playStage === 'question') {
      setQuestionTime(Date.now());
    }
  }, [playStage]);

  useEffect(() => {
    const highscoreRef = query(
      ref(database, `games/${roomCode}/highscore`),
      orderByValue()
    );
    onValue(highscoreRef, (snapshot) => {
      const highscoreList = snapshot.val();
      if (highscoreList) {
        let spots = 0;
        let spot = 0;
        snapshot.forEach((child) => {
          spots++;
          if (child.key === auth.currentUser.uid) {
            spot = spots;
          }
        });
        setPlace(spots - spot + 1);
      }
    });
    return () => {
      off(highscoreRef);
    };
  }, [roomCode]);

  if (loading || !auth === true) {
    return <Loading noVideo />;
  } else {
    return (
      <>
        {playStage === 'join' && (
          <Join
            room={roomCode}
            setRoom={(e) => setRoomCode(e)}
            validRoom={validRoom}
            name={name}
            validName={validName}
            setName={(e) => setDisplayName(e)}
            error={error}
          />
        )}
        {playStage === 'lobby' && (
          <Lobby
            text={`Hang on ${name}, we are waiting for the host to start the game`}
          />
        )}
        {playStage === 'waiting' && (
          <Lobby text={`Game is about to begin, get ready ${name}`} />
        )}
        {playStage === 'question' && (
          <Question
            title={currentQuestion.question}
            time={settings.time.question - 100}
            submitAnswer={submitAnswer}
            position={position}
            setPosition={setPosition}
            answered={answered}
            setAnswered={setAnswered}
            hardcore={hardcore}
            theme={theme}
          />
        )}
        {playStage === 'points' && (
          <Points
            place={place}
            meters={distance}
            time={{ answer: answerTime, question: questionTime }}
            points={points}
          />
        )}
        {playStage === 'end' && (
          <End place={place} submitRating={submitRating} exitQuiz={exitQuiz} />
        )}
      </>
    );
  }
};

export default Play;
