import React from 'react';
import styles from './toggle.module.scss';
import PropTypes from 'prop-types';
import InputLabel from '../inputLabel/inputLabel';
import classNames from 'classnames';

const Toggle = ({ title, description, value, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <button
        className={classNames(styles.toggle, {
          [styles.active]: value === true,
        })}
        onClick={() => {
          onChange(!value);
        }}
      >
        <div className={styles.ball} />
      </button>
      <InputLabel title={title} description={description} />
    </div>
  );
};

Toggle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  title: null,
  description: null,
  value: false,
  onChange: null,
};

export default Toggle;
