import React, { useState, useEffect, useCallback } from 'react';
import styles from './imageInput.module.scss';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';
import { storage, auth } from '../../utils/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import randomCode from '../../utils/randomCode';

const Input = ({ onChange, value, ...props }) => {
  const [imageUrl, setImageUrl] = useState(value);
  const [uploading, setUploading] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [key, setKey] = useState(randomCode(32));

  useEffect(() => {
    setKey(randomCode(32));
    setPreviewLoaded(false);
    setImageUrl(value);
  }, [value]);

  const setImageData = useCallback(
    (data) => {
      onChange(data);
    },
    [onChange]
  );

  const deleteImage = useCallback(() => {
    setImageData('');
  }, [setImageData]);

  const uploadFromBlobAsync = useCallback(
    async ({ blobUrl, name }) => {
      if (!blobUrl || !name) return null;
      try {
        const blob = await fetch(blobUrl).then((r) => r.blob());
        const storageRef = ref(
          storage,
          `images/${auth.currentUser.uid}/${key}`
        );
        uploadBytes(storageRef, blob).then(async () => {
          getDownloadURL(ref(storage, storageRef))
            .then((url) => {
              setImageData(url);
              setUploading(false);
            })
            .catch((error) => {
              setImageData('');
              setUploading(false);
            });
        });
      } catch (error) {
        throw error;
      }
    },
    [setImageData, key]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles?.[0];

      setUploading(true);
      try {
        await uploadFromBlobAsync({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
        });
      } catch (e) {
        setUploading(false);
        return;
      }
    },
    [uploadFromBlobAsync]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
  });

  return (
    <div>
      <input
        hidden
        disabled
        value={imageUrl}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <div className={styles.wrapper}>
        {imageUrl === '' ? (
          <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            <p>Click or drag an image</p>
          </div>
        ) : (
          <div className={styles.value}>
            <img
              className={styles.image}
              style={{ opacity: previewLoaded === false ? 0 : 1 }}
              src={imageUrl}
              alt={'uploaded'}
              onLoad={() => {
                setPreviewLoaded(true);
              }}
            />
            {previewLoaded === false && <div>Loading image</div>}
            <button className={styles.delete} onClick={deleteImage}>
              <MdClose />
            </button>
          </div>
        )}
        {uploading && <div className={styles.loading}>Uploading</div>}
      </div>
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Input.defaultProps = {
  onChange: null,
  value: '',
};

export default Input;
