const coordinatesToMeters = (start, stop) => {
  var R = 6371; // km
  var dLat = toRad(stop.lat - start.lat);
  var dLon = toRad(stop.lng - start.lng);
  var lat1 = toRad(start.lat);
  var lat2 = toRad(stop.lat);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000;
};

const toRad = (Value) => {
  return (Value * Math.PI) / 180;
};

export default coordinatesToMeters;
