import React from 'react';
import styles from './input.module.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  icon,
  area,
  large,
  stretch,
  alternative,
  centered,
  uppercase,
  maxLength,
  numbersOnly,
  onChange,
  className,
  ...props
}) => {
  const allProps = {
    className: classNames(
      styles.input,
      { [styles.large]: large === true },
      { [styles.centered]: centered === true },
      { [styles.uppercase]: uppercase === true },
      { [styles.alternative]: alternative === true },
      className
    ),
    onChange: (e) => {
      if (onChange) {
        if (maxLength && e.target.value.length > maxLength) {
          e.target.value = e.target.value.substr(0, maxLength);
        }
        if (numbersOnly === true) {
          const allowedChars = [
            '#',
            '+',
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
          ];
          for (let i = 0; i < e.target.value.length; i++) {
            const char = e.target.value.charAt(i);
            if (allowedChars.includes(char) === false) {
              e.target.value = e.target.value.replace(char, '');
            }
          }
        }
        if (uppercase === true) {
          e.target.value = e.target.value.toUpperCase();
        }
        onChange(e.target.value);
      }
    },
  };
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.stretch]: stretch === true,
      })}
    >
      <div className={styles.wrapper}>
        {area ? (
          <textarea {...allProps} {...props} />
        ) : (
          <input {...allProps} {...props} />
        )}

        {icon && <div className={styles.icon}>{icon()}</div>}
      </div>
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.func,
  area: PropTypes.bool,
  large: PropTypes.bool,
  stretch: PropTypes.bool,
  centered: PropTypes.bool,
  uppercase: PropTypes.bool,
  alternative: PropTypes.bool,
  maxLength: PropTypes.number,
  numbersOnly: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Input.defaultProps = {
  icon: null,
  area: false,
  large: false,
  stretch: false,
  centered: false,
  uppercase: false,
  alternative: false,
  maxLength: null,
  numbersOnly: false,
  onChange: null,
  className: null,
};

export default Input;
