import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './general.module.scss';
import { MdChevronLeft } from 'react-icons/md';

import Heading from '../../../components/heading/heading';
import Button from '../../../components/button/button';
import SmallButton from '../../../components/smallButton/smallButton';
import Toggle from '../../../components/toggle/toggle';
import Input from '../../../components/input/input';
import ImageInput from '../../../components/imageInput/imageInput';
import classNames from 'classnames';

import theme0 from '../../../assets/theme0.png';
import theme1 from '../../../assets/theme1.png';
import theme2 from '../../../assets/theme2.png';

const General = ({
  settings,
  setSettings,
  exitQuiz,
  saveQuiz,
  goToQuestions,
  validSettings,
  validQuiz,
  launchQuiz,
}) => {
  const [name, setName] = useState(settings.name);
  const [description, setDescription] = useState(settings.description);
  const [image, setImage] = useState(settings.image);
  const [hard, setHard] = useState(settings.hard);
  const [free, setFree] = useState(settings.free);
  const [imperial, setImperial] = useState(settings.imperial);
  const [theme, setTheme] = useState(settings.theme);

  useEffect(() => {
    const newSettings = {
      name: name,
      description: description,
      image: image,
      hard: hard,
      free: free,
      imperial: imperial,
      theme: theme,
    };
    setSettings(newSettings);
  }, [setSettings, name, description, image, hard, free, imperial, theme]);

  return (
    <div className={styles.general}>
      <div className={styles.header}>
        <SmallButton onClick={exitQuiz} icon={MdChevronLeft} small>
          Exit quiz
        </SmallButton>
        {validQuiz && <Button onClick={launchQuiz}>Start quiz</Button>}
      </div>
      <div className={styles.data}>
        <div className={styles.header}>
          <Heading className={styles.title}>General</Heading>
        </div>
        <div className={styles.form}>
          <div className={styles.list}>
            <div className={styles.section}>
              <div className={styles.label}>Quiz name*</div>
              <div className={styles.input}>
                <Input stretch value={name} onChange={setName} />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.label}>Description</div>
              <div className={styles.input}>
                <Input
                  area
                  rows={4}
                  stretch
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.label}>Image</div>
              <div className={styles.input}>
                <ImageInput onChange={setImage} value={image} />
              </div>
            </div>
          </div>
          <div className={styles.list}>
            <div className={styles.section}>
              <div className={styles.input}>
                <Toggle
                  title={'Hard mode'}
                  description={
                    'If selected, the map is displayed without labels'
                  }
                  value={hard}
                  onChange={setHard}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.input}>
                <Toggle
                  title={'Make public?'}
                  description={
                    'If selected, anyone can find and play this game'
                  }
                  value={free}
                  onChange={setFree}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.input}>
                <Toggle
                  title={'Imperial units?'}
                  description={'If selected, the game will use Imperial units'}
                  value={imperial}
                  onChange={setImperial}
                />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.label}>Map theme</div>
              <div className={styles.input}>
                <div className={styles.themes}>
                  <button
                    className={classNames(styles.theme, {
                      [styles.active]: theme === 0,
                    })}
                    onClick={() => {
                      setTheme(0);
                    }}
                  >
                    <div className={styles.border} />
                    <img src={theme0} alt={'Theme normal'} />
                  </button>
                  <button
                    className={classNames(styles.theme, {
                      [styles.active]: theme === 1,
                    })}
                    onClick={() => {
                      setTheme(1);
                    }}
                  >
                    <div className={styles.border} />
                    <img src={theme1} alt={'Theme dark'} />
                  </button>
                  <button
                    className={classNames(styles.theme, {
                      [styles.active]: theme === 2,
                    })}
                    onClick={() => {
                      setTheme(2);
                    }}
                  >
                    <div className={styles.border} />
                    <img src={theme2} alt={'Theme sattelite'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div>
            {name !== '' && (
              <SmallButton onClick={saveQuiz} small>
                Save quiz
              </SmallButton>
            )}
          </div>
          <Button onClick={goToQuestions} disabled={!validSettings}>
            Go to questions
          </Button>
        </div>
      </div>
    </div>
  );
};

General.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  exitQuiz: PropTypes.func.isRequired,
  saveQuiz: PropTypes.func.isRequired,
  goToQuestions: PropTypes.func.isRequired,
  validSettings: PropTypes.bool.isRequired,
  validQuiz: PropTypes.bool,
  launchQuiz: PropTypes.func,
};

General.defaultProps = {
  settings: null,
  setSettings: null,
  exitQuiz: null,
  saveQuiz: null,
  goToQuestions: null,
  validSettings: false,
  validQuiz: false,
  launchQuiz: null,
};

export default General;
