import React, { useState, useEffect, useContext } from 'react';
import { database, auth } from '../../utils/firebase';
import { signOut, updateProfile, deleteUser } from 'firebase/auth';
import { SettingsContext } from '../../contexts/settings-provider';
import { ref, set } from 'firebase/database';
import { MdLockOutline } from 'react-icons/md';

import Layout from '../../components/layout/layout';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import Main from '../../components/main/main';
import Header from '../../components/header/header';
import SmallButton from '../../components/smallButton/smallButton';
import Loading from '../../components/loading/loading';
import Error from '../../components/error/error';

import styles from './profile.module.scss';
import Heading from '../../components/heading/heading';
import ProfilePic from '../../components/profilePic/profilePic';

const Profile = () => {
  const [settings, dispatch] = useContext(SettingsContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [firstTimeUser, setFirstTimeUser] = useState(false);

  const [newDisplayName, setNewDisplayName] = useState(
    auth.currentUser.displayName ? auth.currentUser.displayName : ''
  );
  const [newDisplayImage] = useState('1');

  const [validNewDisplayName, setValidNewDisplayName] = useState(false);

  useEffect(() => {
    if (auth.currentUser.displayName) {
      setFirstTimeUser(false);
    } else {
      setFirstTimeUser(true);
    }
  }, []);

  useEffect(() => {
    if (
      newDisplayName.length >= 3 &&
      newDisplayName.length <= 16 &&
      newDisplayName !== auth.currentUser.displayName
    ) {
      setValidNewDisplayName(true);
    } else {
      setValidNewDisplayName(false);
    }
  }, [newDisplayName]);

  const updateUser = () => {
    setLoading(true);
    updateProfile(auth.currentUser, {
      displayName: newDisplayName,
      photoURL: newDisplayImage,
    })
      .then(() => {
        if (settings.user !== false) {
          setLoading(false);
        }
        setError(false);
        setErrorMessage('');
        dispatch({ type: 'update_user', payload: auth.currentUser });

        const playerRef = ref(
          database,
          `users/${auth.currentUser.uid}/profile`
        );
        set(playerRef, {
          name: newDisplayName,
          image: newDisplayImage,
          phone: auth.currentUser.phoneNumber,
        });

        setValidNewDisplayName(false);

        if (firstTimeUser === true) {
          setFirstTimeUser(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        setErrorMessage(error.toString());
      });
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        console.log('signed out');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProfile = () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This is an ireversible act.'
      )
    ) {
      const user = auth.currentUser;
      deleteUser(user)
        .then(() => {
          setLoading(false);
          setError(false);
          setErrorMessage('');
          logOut();
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          if (
            error.toString() ===
            'FirebaseError: Firebase: Error (auth/requires-recent-login).'
          ) {
            setErrorMessage(
              'Its been too long since you logged into your accout, log in and out of your account to proceed with deletion of your account'
            );
          } else {
            setErrorMessage(error.toString());
          }
        });
    }
  };

  return (
    <Main>
      <Header showBackButton={true}>
        <SmallButton
          onClick={() => {
            deleteProfile();
          }}
        >
          Delete account
        </SmallButton>
      </Header>
      <Layout center>
        {loading && <Loading />}
        {firstTimeUser && (
          <div className={styles.intro}>
            <Heading>Complete your profile to play</Heading>
          </div>
        )}
        {error && <Error>{errorMessage}</Error>}
        <div className={styles.content}>
          <ProfilePic
            className={styles.picture}
            image={newDisplayImage}
            alt={newDisplayName}
          />

          <div className={styles.fields}>
            <Input
              stretch
              title={'Phone'}
              value={auth.currentUser.phoneNumber}
              icon={MdLockOutline}
              disabled
            />
            <Input
              stretch
              title={'Display name'}
              placeholder={'Username (3-16 chars)'}
              value={newDisplayName}
              onChange={setNewDisplayName}
            />
            <div className={styles.buttons}>
              <Button
                disabled={!validNewDisplayName}
                onClick={() => {
                  updateUser();
                }}
              >
                Save
              </Button>
              <Button
                secondary
                onClick={() => {
                  logOut();
                }}
              >
                Sign out
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </Main>
  );
};

export default Profile;
