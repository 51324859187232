import React, { useRef, useEffect, useContext, useCallback } from 'react';
import styles from './displayMap.module.scss';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import { SettingsContext } from '../../contexts/settings-provider';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import coordinatesToMeters from '../../utils/coodrinatesToMeters';
import formatMeters from '../../utils/formatMeters';
import getMapTheme from '../../utils/getMapTheme';

mapboxgl.accessToken =
  'pk.eyJ1Ijoibm90ZXRoZW5vdGUiLCJhIjoiY2t3ZGZjd3RmMXV2cTJvcW1wcm5mczg3ZCJ9.b4fGC2AoXtvBLqgSwX4oew';

function sortByScore(a, b) {
  if (a.points < b.points) {
    return 1;
  }
  if (a.points > b.points) {
    return -1;
  }
  return 0;
}

const DisplayMap = ({
  hardcore,
  theme,
  position,
  zoom,
  mapStage,
  scoredPlayers,
}) => {
  const [settings] = useContext(SettingsContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markers = useRef([]);

  // CHOOSE THEME
  let mapTheme = getMapTheme(hardcore, theme);

  // INITIALIZE MAP
  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapTheme,
      center: [position.lng, position.lat],
      zoom: zoom.start,
      pitch: 0,
    });

    if (theme === 2) {
      map.current.on('load', () => {
        map.current.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14,
        });
        // add the DEM source as a terrain layer with exaggerated height
        map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 2 });
      });
    }
  });

  // ANIMATE ON QUESTION
  useEffect(() => {
    if (mapStage === 'question' || mapStage === 'end') {
      const outroTime = 2000;
      markers.current.forEach((marker, i) => {
        const mapLayer = map.current.getLayer(`layer${i}`);
        const mapSource = map.current.getSource(`source${i}`);
        if (typeof mapLayer !== 'undefined') {
          map.current.removeLayer(`layer${i}`);
        }
        if (typeof mapSource !== 'undefined') {
          map.current.removeSource(`source${i}`);
        }
        marker.remove();
      });
      markers.current = [];

      map.current.easeTo({
        center: [position.lng, position.lat],
        zoom: zoom.start,
        speed: 0.2,
        curve: 1,
        duration: 1000,
      });
      setTimeout(() => {
        map.current.easeTo({
          center: [position.lng, position.lat],
          zoom: zoom.end,
          speed: 1,
          curve: 1,
          duration: settings.time.question - outroTime,
        });
      }, outroTime);
    }
  }, [mapStage, position, zoom, settings.time.question, mapTheme]);

  const addPin = useCallback((pin) => {
    markers.current.push(pin);
    pin.addTo(map.current);
  }, []);

  const scorePlayer = useCallback(
    (player, index) => {
      if (player && player.answer) {
        const playerPin = document.createElement('div');
        playerPin.className = classNames(styles.marker, styles.playerMarker);

        const pinLabel = document.createElement('div');
        pinLabel.className = styles.tag;

        const pinName = document.createElement('div');
        pinName.innerText = `${player.name}`;
        pinName.className = styles.name;
        pinLabel.appendChild(pinName);

        const pinScore = document.createElement('div');
        const distance = formatMeters(
          coordinatesToMeters(position, player.answer)
        );
        pinScore.innerText = `${distance.value}${distance.unit}`;
        pinScore.className = styles.score;
        pinLabel.appendChild(pinScore);

        playerPin.appendChild(pinLabel);

        if (player.answer) {
          addPin(
            new mapboxgl.Marker(playerPin).setLngLat([
              player.answer.lng,
              player.answer.lat,
            ])
          );

          const sourceId = `source${index}`;

          const mapLayer = map.current.getLayer(`layer${index}`);
          const mapSource = map.current.getSource(`source${index}`);
          if (
            typeof mapLayer === 'undefined' &&
            typeof mapSource === 'undefined'
          ) {
            map.current.addSource(sourceId, {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [player.answer.lng, player.answer.lat],
                    [position.lng, position.lat],
                  ],
                },
              },
            });
            map.current.addLayer({
              id: `layer${index}`,
              type: 'line',
              source: sourceId,
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#ffffff',
                'line-width': 2,
                'line-dasharray': [2, 2],
              },
            });
          }

          const bounds = new mapboxgl.LngLatBounds(
            [player.answer.lng, player.answer.lat],
            [position.lng, position.lat]
          );

          map.current.fitBounds(bounds, {
            padding: 450,
            duration: settings.time.points / 2,
          });
        }
      }
    },
    [settings.time.points, map, position, addPin]
  );

  // ANIMATE ON RESULTS
  useEffect(() => {
    if (mapStage === 'results') {
      const solutionPin = document.createElement('div');
      solutionPin.className = classNames(styles.marker, styles.solutionMarker);
      addPin(
        new mapboxgl.Marker(solutionPin).setLngLat([position.lng, position.lat])
      );

      const sortedAnswers = scoredPlayers.sort(sortByScore);

      let currentIndex = 0;
      const player = sortedAnswers[currentIndex];
      scorePlayer(player, currentIndex);
      currentIndex++;

      const answerTimer = setInterval(() => {
        const player = sortedAnswers[currentIndex];
        scorePlayer(player, currentIndex);
        currentIndex++;
        if (currentIndex >= scoredPlayers.length) {
          clearInterval(answerTimer);
        }
      }, settings.time.points);
    }
  }, [
    mapStage,
    position,
    scoredPlayers,
    zoom,
    settings.time.points,
    scorePlayer,
    addPin,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.map} ref={mapContainer}></div>
    </div>
  );
};
DisplayMap.propTypes = {
  hardcore: PropTypes.bool,
  theme: PropTypes.number,
  position: PropTypes.object,
  zoom: PropTypes.object,
  mapStage: PropTypes.string,
  scoredPlayers: PropTypes.array,
};

DisplayMap.defaultProps = {
  position: { lat: 0, lng: 0 },
  hardcore: false,
  theme: 0,
  zoom: { start: 21, end: 1 },
  mapStage: 'question',
  scoredPlayers: [],
};

export default DisplayMap;
