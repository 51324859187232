import React from 'react';
import PropTypes from 'prop-types';
import { MdCheck } from 'react-icons/md';

import styles from './question.module.scss';

import classNames from 'classnames';
import Clock from '../../../components/clock/clock';

const Question = ({
  image,
  question,
  questionIndex,
  questionsTotal,
  questionTime,
  joinedPlayers,
  answeredPlayers,
}) => {
  const playerList = joinedPlayers.map((player) => {
    return (
      <div className={styles.player} key={player.uid}>
        <div
          className={classNames(styles.status, {
            [styles.pending]:
              answeredPlayers.some((answer) => answer.uid === player.uid) ===
              false,
          })}
        >
          <MdCheck />
        </div>
        <div className={styles.name}>{player.name}</div>
      </div>
    );
  });

  return (
    <div className={styles.question}>
      <div
        className={classNames(
          styles.header,
          styles.animateIn,
          styles.animateTop
        )}
      >
        <div className={styles.players}>{playerList}</div>
      </div>
      <div className={classNames(styles.footer, styles.animateIn)}>
        <div className={styles.content}>
          {image && (
            <div className={styles.image}>
              <img src={image} alt={'question'} />
            </div>
          )}
          <div className={styles.text}>
            <div className={styles.meta}>
              <div className={styles.number}>
                Question {questionIndex + 1}/{questionsTotal}
              </div>
              <div className={styles.title}>{question}</div>
            </div>
            <Clock time={questionTime} />
          </div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  image: PropTypes.string,
  question: PropTypes.string,
  joinedPlayers: PropTypes.array,
  answeredPlayers: PropTypes.array,
  questionTime: PropTypes.number,
  questionIndex: PropTypes.number,
  questionsTotal: PropTypes.number,
};

Question.defaultProps = {
  image: null,
  question: null,
  joinedPlayers: [],
  answeredPlayers: [],
  questionTime: null,
  questionIndex: null,
  questionsTotal: null,
};
export default Question;
